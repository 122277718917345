import React, {useState} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function TarjetaServicio({tipo, getServicios,count}){

	return(
		<div className="col-6 col-md-3 col-xl-2 mb-0 my-3 new-box">
			<div className="btn btn-primary w-100 h-100 d-flex text-small" onClick={()=>getServicios(tipo.id, tipo.imagen)}>
				<div className="card-body text-center my-auto">
					<img className="img-fluid" src={tipo.imagen} />
					<h3 className="my-auto d-block align-middle pt-1">{tipo.desc_servicio}</h3>
					<span class="badge text-bg-danger mt-2">{count}</span>
				</div>
			</div>
		</div>
	);
}

export default TarjetaServicio;