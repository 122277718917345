import React, {useState, useEffect, useRef} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


import {Get,Post} from './../../Tools/Helper';

import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';

function ListaFull({setLoading}){

	let initialRef = useRef(React.createRef());

	const [status, setStatus] = useState({status: null, message: ""});
	const [financieroTabla, setFinancieroTabla] = useState([]);

	useEffect(() => {

		const getLista = async e =>{
    
			setStatus({status: null, message: ""});
			setLoading(true);
	
			const tablas = await Get('lista-servicios');
			setFinancieroTabla(tablas.data);

			setLoading(false);

		}

		getLista();

		if(initialRef.current){
			initialRef.current.scrollIntoView({ 
		       behavior: "smooth", 
		    });
		}

	}, []);

  	const columns = [
  		{
	        name: 'nombre_cliente',
	        selector: row => row.nombre_cliente,
	        cellExport: row => row.nombre_cliente,
	        sortable: true,
	    },
	    {
	        name: 'tipo_servicio',
	        selector: row => row.tipo_servicio,
	        cellExport: row => row.tipo_servicio,
	    },
	    {
	        name: 'plan',
	        selector: row => row.plan,
	        cellExport: row => row.plan,
	    },
	    {
	        name: 'tipo_pago',
	        selector: row => row.tipo_pago,
	        cellExport: row => row.tipo_pago,
	    },
	    {
	        name: 'dominio',
	        selector: row => row.dominio,
	        cellExport: row => row.dominio,
	    },
	    {
	        name: 'fecha_contratacion',
	        selector: row => row.fecha_contratacion,
	        cellExport: row => row.fecha_contratacion,
	    },
	    {
	        name: 'fecha_pago_cliente',
	        selector: row => row.fecha_pago_cliente,
	        cellExport: row => row.fecha_pago_cliente,
	    },
	    {
	        name: 'fecha_vencimiento_interno',
	        selector: row => row.fecha_vencimiento_interno,
	        cellExport: row => row.fecha_vencimiento_interno,
	    },
	    {
	        name: 'fecha_renovacion',
	        selector: row => row.fecha_renovacion,
	        cellExport: row => row.fecha_renovacion,
	    },
	    {
	        name: 'costo_total_servicio',
	        selector: row => row.costo_total_servicio,
	        cellExport: row => row.costo_total_servicio,
	    },
	    {
	        name: 'costo_contrato',
	        selector: row => row.costo_contrato,
	        cellExport: row => row.costo_contrato,
	    },
	    {
	        name: 'costo_compra',
	        selector: row => row.costo_compra,
	        cellExport: row => row.costo_compra,
	    },
	    
	    

	];


	const data = financieroTabla;

	const tableData = {
	    columns,
	    data,
	};
	
	return(
		<>
			<div className="title p-4" ref={initialRef}>
				<h3>Lista Servicios completa</h3>
			</div>

			<div className="container-fluid">
				<div className="row p-3">
					
				<div className="col-12">
						<DataTableExtensions {...tableData}
							export={true}
							print
							exportHeaders={true}
							fileName={"Reporte"}
				        	filterPlaceholder="Buscar..."	        	
				         >
						<DataTable
				            striped
				            defaultSortFieldId={1}
				            noDataComponent=<div className="p-5">No se han encontrado datos.</div>
				        />
		        </DataTableExtensions>
					
				</div>
			</div>

		</div>
		</>
	);
}

export default ListaFull;