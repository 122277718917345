import React, {useState, useEffect} from 'react';

import {Get} from '../../Tools/Helper';
import TipoServicios from './Catalogos/Servicios/TipoServicios';
import TipoPlanes from './Catalogos/Planes/TipoPlanes';

function Catalogos2({setLoading}){

	return(
		<>
			<div className="title p-4"><h3>Catalogo de Servicios</h3>
				
			</div>
			<TipoServicios setLoading={setLoading} />
		</>
	);
}

export default Catalogos2;