import React, {useState, useEffect} from 'react';

import { Modal, Button } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';

import {Post} from '../../../../Tools/Helper';
import StatusBar from '../../../../Tools/StatusBar';

function ModalServicio({show, handleClose, servicio}){

	library.add(faTriangleExclamation);

	const [dataForm,setDataForm] = useState({desc_tipo_servicio: "", costo:"", tipo_servicio_id: (servicio ? servicio.id : null), tipo_costo_id:2, status:1});
	const [loading,setLoading] = useState(false);
	const [status,setStatus] = useState({status: null, msg: ""});

	const handleFormChange = e => {
		setDataForm({...dataForm, 
			[e.target.name]: e.target.value
		});
	}

	useEffect(() => { 
		setDataForm({desc_tipo_servicio: "", costo:"", tipo_servicio_id: (servicio ? servicio.id : null), tipo_costo_id:2, status:1});
	}, [servicio, setDataForm]);

	const create = async() =>{
	    setStatus({status: null, message: ""});
		setLoading(true);;

		const submit = await Post('tipo-plan', dataForm);

		if(submit.code === 201){
			setStatus({status:'success', message:'Se ha capturado el servicio con exito.'});
		}else{
			setStatus({...submit, status: 'danger'});
		}
		
		setLoading(false);
	}

	const close = () =>{
	    setDataForm({desc_tipo_servicio: "", costo:"", tipo_servicio_id: null, tipo_costo_id:2});
	    handleClose();
	}

	return(
		<div>
			<Modal show={show} onHide={handleClose} backdrop="static">
			  <Modal.Header closeButton>
			    <Modal.Title>Agregar nuevo plan a <span className="fw-bold">{(servicio ? servicio.desc_servicio : "")}</span></Modal.Title>
			  </Modal.Header>
	
			  <Modal.Body className="">
			  	<div className="row">
			  		<div className="col-12">
			  			<StatusBar status={status} />
			  		</div>
			  		{(status.status !== "success" && loading === false) ? <><div className="col-12 mb-3">
				  			<label className="form-label">Descripción:</label>
				  			<input type="text" name="desc_tipo_servicio" placeholder="" 
								className="form-control new-box px-2" value={dataForm.desc_tipo_servicio} onChange={handleFormChange} />
						</div>
						<div className="col-12 mb-3">
				  			<label className="form-label">Costo:</label>
				  			<input type="text" name="costo" placeholder="" 
								className="form-control new-box px-2" value={dataForm.costo} onChange={handleFormChange} />
						</div></> : ""}
						{ loading ? 
					  		<div className="col-12 text-center"><div className="spinner-border mx-5 mt-5" role="status">
						  		<span className="visually-hidden">Loading...</span>
							</div>
							<h5 className="mb-5">Cargando...</h5>
							</div> : ""}
				</div>
			  </Modal.Body>
	
			  <Modal.Footer>
			  	<div className="row w-100">
			  		<div className="col-6 mb-3">
			  			{loading ? "" : 
			  			<Button variant="primary" onClick={close} className="w-100">
			        		Cerrar
			        	</Button>}
			  		</div>
			  		<div className="col-6 mb-3">
			        	{(status.status !== "success" && loading === false) ? 
			        		<button className="btn btn-success px-2 w-100" onClick={()=>create()}>
								Crear
							</button> : ""}
			  		</div>
				</div>
			  </Modal.Footer>
			</Modal>
		</div>
	);
}

export default ModalServicio;