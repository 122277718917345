import React from 'react';
import { NavLink } from "react-router-dom";
import Logo from "../logo-gols.png";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faChartLine, faBook, faUsers, faArrowRightFromBracket, faReceipt } from '@fortawesome/free-solid-svg-icons';

function Sidebar({data}){

	library.add(faChartLine, faBook, faUsers, faArrowRightFromBracket, faReceipt);

	return(
		<div className="sidenav d-none d-lg-block no-print" id="collapseExample-hidden">
			<div className="p-3">
				<img className="img-fluid title mb-3" src={Logo} />
			</div>
			{data ? 
				<>
				<NavLink to="/" className="py-3">
					<FontAwesomeIcon icon="fa-chart-line" className="pe-3" /> Dashboard
				</NavLink>
				<NavLink to="/financiero-pendiente" className="py-3">
					<FontAwesomeIcon icon="fa-receipt" className="pe-3" /> Analitico Financiero Pendiente
				</NavLink>
				<NavLink to="/financiero-total" className="py-3">
					<FontAwesomeIcon icon="fa-receipt" className="pe-3" /> Analitico Financiero Total
				</NavLink>
				<NavLink to="/servicios" className="py-3">
					<FontAwesomeIcon icon="fa-receipt" className="pe-3" /> Servicios
				</NavLink>
				<NavLink to="/clientes" className="py-3">
				  <FontAwesomeIcon icon="fa-users" className="pe-3" /> Clientes
				</NavLink>
				<NavLink to="/catalogo-servicios" className="py-3">
					<FontAwesomeIcon icon="fa-book" className="pe-3" /> Catalogo Servicios
				</NavLink>
				<NavLink to="/catalogo-planes" className="py-3">
					<FontAwesomeIcon icon="fa-book" className="pe-3" /> Catalogo Planes
				</NavLink>
				<hr />
				<NavLink to="/logout" className="py-3">
					<FontAwesomeIcon icon="fa-arrow-right-from-bracket" className="pe-3" /> Cerrar Sesión
				</NavLink> </> :
				<NavLink to="/login" className="py-3">
					<FontAwesomeIcon icon="fa-user" className="pe-3" /> Iniciar Sesión
				</NavLink>
				}
			
			<hr />
			<p className="text-center">(C) Golsystems 2024 (v0.1.0)</p>
		</div>
	);
}

export default Sidebar;