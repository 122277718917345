import React, {useEffect, useState} from 'react';

import { Modal, Button } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';

import {Post} from '../../Tools/Helper';
import StatusBar from '../../Tools/StatusBar';

function NuevoClienteModal({show, handleClose, getLista}){

	const [data,setData] = useState({});

	const [status,setStatus] = useState({status: null, msg: ""});
	const [loading,setLoading] = useState(false);

	useEffect(() => { 
		const clearFields = () =>{
			setData({
				nombre_cliente:'',
				correo:'',
				telefono:'',
				nombre_contacto: '',
				telefono_contacto: '',
				correo_contacto:'',
			});
			setStatus({status: null, msg: ""});
		}

		if(show){
			clearFields();
		}

	}, [show]);


	const handleChangeText = e => {
		setData({...data, [e.target.name]:e.target.value});
	};

	const submitServicio = async() => {

		setStatus({status: null, message: ""});
		setLoading(true);

		const submit = await Post('clientes', data);

		if(submit.code === 201){
			setStatus({status:'success', message:'Se ha creado el cliente correctamente.'});
		}else{
			setStatus({...submit, status: 'danger'});
		}

		setLoading(false);
		await getLista();
		
	};

	return(
		<Modal show={show} onHide={handleClose} backdrop="static">
		  <Modal.Header closeButton>
		    <Modal.Title>Agregar nuevo cliente</Modal.Title>
		  </Modal.Header>

		  <Modal.Body className="text-center">
		  	<StatusBar status={status} />
		  	{ loading === true ? 
		  		<><div className="spinner-border mx-5 mt-5" role="status">
			  		<span className="visually-hidden">Loading...</span>
				</div>
				<h5 className="mb-5">Cargando...</h5>
				</> : ""}

		    {(status.status !== "success" && loading === false) ? <div className="row">
				<div className="col-12 mb-3">
					  <label className="form-label">Nombre cliente</label>
					  <input className="form-control" value={data.nombre_cliente}
					  	name="nombre_cliente" onChange={handleChangeText} />
			    </div>
			    <div className="col-12 mb-3">
					  <label className="form-label">Correo</label>
					  <input className="form-control" value={data.correo}
					  	name="correo" onChange={handleChangeText} />
			    </div>
			    <div className="col-6 mb-3">
					  <label className="form-label">Telefono</label>
					  <input className="form-control" value={data.telefono1}
					  	name="telefono1" onChange={handleChangeText} />
			    </div>
			    <div className="col-6 mb-3">
					  <label className="form-label">Nombre Contacto</label>
					  <input className="form-control" value={data.nombre_contacto}
					  	name="nombre_contacto" onChange={handleChangeText} />
			    </div>
			    <div className="col-6 mb-3">
					  <label className="form-label">Telefono Contacto</label>
					  <input className="form-control" value={data.telefono_contacto}
					  	name="telefono_contacto" onChange={handleChangeText} />
			    </div>
			    <div className="col-6 mb-3">
					  <label className="form-label">Correo Contacto</label>
					  <input className="form-control" value={data.correo_contacto}
					  	name="correo_contacto" onChange={handleChangeText} />
			    </div>
		    </div> : ""}
		  </Modal.Body>

          {
          	!loading ?
			<Modal.Footer>
				<div className="row w-100">
					{
						status.status === 'success' ?
						
							<div className="ms-auto col-6 mb-3">
								<Button variant="primary" onClick={handleClose} className="w-100">
									Cerrar
								</Button>
							</div>
						 : 
						<>
							<div className="col-6 mb-3">
								<button className="btn btn-success w-100" onClick={submitServicio}>Agregar Cliente</button>
							</div>
							<div className="col-6 mb-3">
								<Button variant="primary" onClick={handleClose} className="w-100">
									Cerrar
								</Button>
							</div>
						</>

					}
					
				</div>
			</Modal.Footer> : ""
          }
		
		</Modal>
	);
}

export default NuevoClienteModal;