import React from 'react';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

function GraficaVencidos({dataset, labels}){

	const formatter = new Intl.NumberFormat('en-US', {
	  style: 'currency',
	  currency: 'USD',

	  // These options are needed to round to whole numbers if that's what you want.
	  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
	  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
	});

	ChartJS.register(
	  CategoryScale,
	  LinearScale,
	  BarElement,
	  Title,
	  Tooltip,
	  Legend
	);

	const options = {
	  responsive: true,
	  plugins: {
	    legend: {
	      position: 'top',
	    },
	    tooltip: {
	    	callbacks:{
	    			label: function(context) {
	    				let label = context.parsed.y || '';

             	return formatter.format(label);
           }
	    	}
        
			}
	  },
	  scales:{
			y: {
	      title: {
		      display: true,
		      text: 'Monto'
	    	},
        ticks: {
        	callback: function(value, index, values) {
             return formatter.format(value);
           }
        }
      },
      x: {
	      title: {
		      display: true,
		      text: 'Mes'
	    	},
        ticks: {
        	callback: function(value, index, values) {
        			let nombreMes = "";
        			switch(this.getLabelForValue(value)){
        				case 1:
        					nombreMes="Enero";
        					break;
        				case 2:
        					nombreMes="Febrero";
        					break;
        				case 3:
        					nombreMes="Marzo";
        					break;
        				case 4:
        					nombreMes="Abril";
        					break;
        				case 5:
        					nombreMes="Mayo";
        					break;
        				case 6:
        					nombreMes="Junio";
        					break;
        				case 7:
        					nombreMes="Julio";
        					break;
        				case 8:
        					nombreMes="Agosto";
        					break;
        				case 9:
        					nombreMes="Septiembre";
        					break;
        				case 10:
        					nombreMes="Octubre";
        					break;
        				case 11:
        					nombreMes="Noviembre";
        					break;
        				case 12:
        					nombreMes="Diciembre";
        					break;
        			}
             return nombreMes;
           }
        }
      }
		},

	};

	const data = {
		labels,
		datasets: dataset
	}

	return(
		<Bar data={data} options={options}
		 />
	);
}

export default GraficaVencidos;