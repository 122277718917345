import React, {useState, useEffect} from 'react';

import {Get} from '../../Tools/Helper';
import TipoServicios from './Catalogos/Servicios/TipoServicios';
import TipoPlanes from './Catalogos/Planes/TipoPlanes';

function Catalogos({setLoading}){

	return(
		<>
			<div className="title p-4"><h3>Catalogo de Planes</h3>
				
			</div>
			<TipoPlanes setLoading={setLoading} />
		</>
	);
}

export default Catalogos;