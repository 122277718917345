import logo from './logo.svg';
import './App.css';
import './style.css';
import {useState} from 'react';

import Navbar from './Template/Navbar';
import Sidebar from './Template/Sidebar';
import Loading from './Tools/Loading';

import Clientes from './Components/Admin/Clientes';
import Servicios from './Components/Admin/Servicios';
import Inicio from './Components/Admin/Inicio';
import Catalogos from './Components/Admin/Catalogos';
import Catalogos2 from './Components/Admin/Catalogos2';
import Index from './Components/Admin/Financiero/Index';
import Index2 from './Components/Admin/Financiero/Index2';
import ListaFull from './Components/Admin/ListaFull';

import InfoEmpresa from './Components/Empresa/InfoEmpresa';

import Login from './Login/Login';
import Logout from './Login/Logout';

import { useNavigate  } from "react-router-dom";

import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";

import { library } from '@fortawesome/fontawesome-svg-core';

function App() {

  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState(JSON.parse(localStorage.getItem("userData")));

  //var dataa = JSON.parse(userData);

  let navigate = useNavigate();

  const loginHandler = (data) =>{
    localStorage.setItem("userData", JSON.stringify(data));
    setUserData(data);
    return navigate("/");
  }

  const logoutHandler = () => {
    localStorage.clear();
    setUserData(null);
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, "700");
    return navigate("/");
  };

  return (
    <>
      
      <Sidebar data={userData} />
      <div className="main">
      <Navbar data={userData} />
            
            { userData !== null ? 
              <>
              <Routes>
                <Route path="/" element={<Inicio setLoading={setLoading} />} />
                <Route path="/servicios" element={<Servicios setLoading={setLoading} />} />
                <Route path="/clientes" element={<Clientes setLoading={setLoading} capturista={userData.nombre} tipoUsuario={userData.tipo_usuario_id} />} />
                <Route path="/logout" element={<Logout logoutHandler={logoutHandler} />} />
                <Route path="/catalogo-planes" element={<Catalogos setLoading={setLoading} />} />
                <Route path="/catalogo-servicios" element={<Catalogos2 setLoading={setLoading} />} />
                <Route path="/financiero-pendiente" element={<Index setLoading={setLoading} />} />
                <Route path="/financiero-total" element={<Index2 setLoading={setLoading} />} />
                <Route path="/lista" element={<ListaFull setLoading={setLoading} />} />
                <Route
                  exact
                  path="/clientes/:idCliente"
                  element={<InfoEmpresa setLoading={setLoading} />}
                />
              </Routes> </> : <Routes>
                <Route path="/login" element={<Login setLoading={setLoading} loginHandler={loginHandler} />} />
                <Route path="*" element={<Login setLoading={setLoading} loginHandler={loginHandler} />} />
              </Routes>
            }

            {//<button onClick={()=>{console.log(userData)}}>sadsad</button>
            }
            <Loading loading={loading} />

       
      </div>
    </>
  );
}

export default App;
