import React, {useRef} from 'react';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faCalendarDays } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {Link} from "react-router-dom";

function DatosServicio({data, img, scrollRef, customStyle, handleShow}){

	
	let totalActivos = 0;
	let totalInactivos = 0;	
	library.add(faCalendarDays);

	const empty = () =>{
		return(<div className="col-12 col-lg-4 mb-0 my-3">
		<div className="card">
			<div className="d-flex card-header expired">
				<h5>No se han encontrado datos</h5>
			</div>
			<div className="card-body p-3">
				<div className="row">
					<div className="col-12 text-center">
						Intente buscar otro tipo de servicio.
					</div>
				</div>
				<hr />
			</div>
		</div>
	</div>)
	}
	
	const servicios = data ? data.length !== 0 ? data.map((servicio, index) => {

	const today = new Date();

	let dateCompare = new Date(servicio.fecha_renovacion);
	// console.log(dateCompare + " .---- " + today);

	let enUnMes = today;
	enUnMes.setDate(enUnMes.getDate() + 30);


	function getType(date){
		if(date < today){
			totalInactivos++;
			return "expired"
		}else{
			if(date < enUnMes){
				return "close"
			}
		}
		totalActivos++;
		return "not-expired"
	}

	function datefilter(date){
		let newDate = new Date(date);
		if(newDate){
            let month = newDate.getUTCMonth();
            let year = newDate.getUTCFullYear();
            let day = newDate.getUTCDate();

            let zeroDay = day < 9 ? "0" : "";
            let zeroMonth = month < 9 ? "0" : "";

            return zeroDay + day + '-' + zeroMonth + (month+1) + '-' + year;
        }
        return null;
    }


		return(
			<div className="col-12 col-md-6 col-xl-4 mb-0 my-3" key={index}>
				<div className={"card new-box " + getType(dateCompare)}>
					<div className={"d-flex card-header"}>
						<div className="col d-flex">
							<Link to={'/clientes/'+servicio.id}><h5 className="my-1 fw-bold">{servicio.nombre_cliente}</h5></Link>
						</div>
						<div className="col-auto text-right my-1">
							<a href={servicio.dominio}>{servicio.dominio}</a>
						</div>
					</div>
					<div className="card-body p-3">
						<div className="row">
							<div className="col-2 text-center">
								<img src={img} className="img-fluid" />
							</div>
							<div className="col-10">
								<h5 className="my-auto"><span className="fw-bold"><FontAwesomeIcon icon="fa-solid fa-calendar-days" /> Contratación: </span>{datefilter(servicio.fecha_contratacion)}</h5>
								<h5 className="my-auto"><span className="fw-bold"><FontAwesomeIcon icon="fa-solid fa-calendar-days" /> Renovación: </span>{datefilter(servicio.fecha_renovacion)}</h5>
								<h5 className="mt-2">{servicio.desc_tipo_servicio} - {servicio.desc_tipo_pago}</h5>
								<h5 className="mb-0">$ {servicio.costo}</h5>
								<button onClick={()=>handleShow(servicio.servicio_id)} className="btn btn-primary mt-2">Ver detalles</button>
							</div>
						</div>
						<hr />
						{servicio.comentario}
					</div>
				</div>
			</div>
		)
	}) : "" : "";

	return(
		<>
		<div className="row pt-4 px-4" ref={scrollRef}>
			<div className="col-12 col-lg-6">
				<h3>Total Activos: {totalActivos}</h3>
			</div>
			<div className="col-12 col-lg-6">
				<h3>Total Inactivos: {totalInactivos}</h3>
			</div>
		</div>
		<div className="row mb-3 full-height" style={customStyle}>
			
			{data !== null ? data.length > 0 ? servicios : empty() : ""}
		</div>
		</>
	);
}

export default DatosServicio;