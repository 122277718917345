import React, {useState} from 'react';
import { Post } from '../Tools/Helper';
import StatusBar from '../Tools/StatusBar';
import { useNavigate  } from "react-router-dom";

export default function Login({setLoading, loginHandler}) {

	const [status, setStatus] = useState({status: null, message: ""});
	const [formData, setFormData] = useState({email: "", password: ""});

	const doLogin = async e =>{
	    e.preventDefault();

	    setStatus({status: null, message: ""});
	    setLoading(true);
	    const submit = await Post('login',formData);

	    if(submit.status === "success"){
	      loginHandler(submit);
	    }else{
	      setFormData({email: "", password: ""});
	    }

	    setStatus(submit);
	    setLoading(false);
	  }

	const handleFormChange = (e) => {
		setFormData({
			...formData,
			[e.target.name]: e.target.value,
		});
	}

  return(
  	<>
  	<div className="row py-4">
	  	<div className="col-12 col-lg-6 mx-auto">
	  		<h3 className="pb-2">Iniciar Sesión {process.env.REACT_APP_TEST}</h3>
	  		<StatusBar status={status} />
			<div className="card">
				<div className="card-body">
					<form>
					  <div className="mb-3">
					    <label for="exampleInputEmail1" className="form-label">Usuario</label>
					    <input type="text" className="form-control" id="email" name="email" value={formData.email} onChange={handleFormChange} />
					  </div>
					  <div className="mb-3">
					    <label for="exampleInputPassword1" className="form-label">Contraseña</label>
					    <input type="password" className="form-control" id="password" name="password" value={formData.password} onChange={handleFormChange} />
					  </div>
					  <button className="btn btn-primary mx-auto d-block px-5" onClick={doLogin} >Acceder</button>
					</form>
				</div>
			</div>
		</div>
	</div>
	</>
  )
}