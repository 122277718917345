import React, {useEffect, useState} from 'react';

import { Modal, Button } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';

import Select from 'react-select';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import {Get,Post} from '../../Tools/Helper';
import StatusBar from '../../Tools/StatusBar';

import { registerLocale, setDefaultLocale } from  "react-datepicker";
import es from 'date-fns/locale/es';

function NuevoServicioPorEmpresa({catalogo, show, handleClose, selectedEmpresa, getLista}){

	registerLocale('es', es);

	const [data,setData] = useState({});
	const [planes, setPlanes] = useState([]);

	const [status,setStatus] = useState({status: null, msg: ""});
	const [loading,setLoading] = useState(false);

	library.add(faTriangleExclamation);

	useEffect(() => { 

		const clearFields = () =>{
			if(catalogo.length !== 0){
				setData({
					cliente_id:selectedEmpresa.id,
					tipo_plan_id:'',
					tipo_pago_id:'',
					fecha_pago_cliente: new Date(),
					fecha_contratacion: new Date(),
					fecha_renovacion: new Date(),
					fecha_renovacion_interno: new Date(),
					ticket: '',
					desc_servicio: 'Servicio',
					costo_total_servicio:'',
					costo_contrato:'',
					dominio:'',
					costo_compra:'',
				});
				setStatus({status: null, msg: ""});
			}
		}

		if(show){
			clearFields();
		}

	}, [show]);

	const handleChangeSelect = (selectedOption, nombre) => {
		setData({...data, [nombre]:selectedOption});
		//console.log(data);
	};

	const handleChangeText = e => {
		setData({...data, [e.target.name]:e.target.value});
	};

	const handleChangeDate = (fecha, nombre) => {
		setData({...data, [nombre]:fecha});
	};

	const filterPlanes = (selectedOption) => {
		let tempArray = [];

		//console.log(selectedOption);
		catalogo.planes.forEach((el)=>{
			//console.log(el);
		    if(el.tipo_servicio_id == selectedOption.id){
		    	tempArray.push(el);
		    }
		});

		setPlanes(tempArray);
		setData({...data, tipo_plan_id:null, tipo_servicio_id:selectedOption});
	};

	function MapList({list, keyName, value}){
		return list.map((item) =>
		<option key={item[keyName]} value={item[keyName]}>{item[value]}</option>);
	}

	function datefilter(date){
		if(date){
            let month = date.getMonth();
            let year = date.getFullYear();
            let day = date.getDate();
            return year+'-'+(month+1)+'-'+(day);
        }
        return null;
    }

	const submitServicio = async() => {

		setStatus({status: null, message: ""});
		setLoading(true);

		let tempData = {...data};

		tempData.user_id = 1;
		tempData.fecha_pago_cliente = datefilter(data.fecha_pago_cliente);
		tempData.fecha_renovacion = datefilter(data.fecha_renovacion);
		tempData.fecha_renovacion_interno = datefilter(data.fecha_renovacion_interno);
		tempData.fecha_contratacion = datefilter(data.fecha_contratacion);
		if(tempData.tipo_plan_id !== '' && tempData.tipo_pago_id !== '' && tempData.tipo_servicio_id !== ''){
			tempData.tipo_plan_id = tempData.tipo_plan_id.id;
			tempData.tipo_pago_id = tempData.tipo_pago_id.id;
			tempData.tipo_servicio_id = tempData.tipo_servicio_id.id;
			const submit = await Post('servicio', tempData);

			if(submit.code === 201){
				await getLista();
				setStatus({status:'success', message:'Se ha capturado el servicio con exito.'});
			}else{
				setStatus({...submit, status: 'danger'});
			}

		}else{
			setStatus({status:'danger', message:'Faltan campos por rellenar... (Servicio, Tipo servicio, Tipo pago)'});
		}

		
		

		/*if(submit.code === 200){
			setStatus({status: null, message: ""});
		}else{
			setStatus(submit.status);
		}*/
		
		setLoading(false);
	};

	return(
		<div>
		
		{selectedEmpresa !== null ? 
				<Modal show={show} onHide={handleClose} backdrop="static">
				  <Modal.Header closeButton>
				    <Modal.Title>Asignar nuevo servicio</Modal.Title>
				  </Modal.Header>
		
				  <Modal.Body className="text-center">
				  	<StatusBar status={status} />
				  	{ loading === true ? 
				  		<><div className="spinner-border mx-5 mt-5" role="status">
					  		<span className="visually-hidden">Loading...</span>
						</div>
						<h5 className="mb-5">Cargando...</h5>
						</> : ""}

				    {(status.status !== "success" && loading === false) ? <div className="row">
				    	<div className="col-8 mb-3">
						  <label className="form-label">Cliente:</label>
						  <input type="text" className="form-control text-center" placeholder="cliente" value={selectedEmpresa.nombre} disabled />
						</div>
						<div className="col-4 mb-3">
							  <label className="form-label">Ticket?</label>
							  <input className="form-control" value={data.ticket}
							  	name="ticket" onChange={handleChangeText} />
					    </div>
						<div className="col-12 mb-3">
						  <label className="form-label">Servicio:</label>
						  <Select 
			                  options={catalogo.servicios}
			                  getOptionValue={(option) => `${option['id']}`}
			                  getOptionLabel={(option) => `${option['desc_servicio']}`}
			                  onChange={(selectedOption)=>filterPlanes(selectedOption)}
			                  value={data.tipo_servicio_id}
			                  placeholder="Seleccione..."
			                />
						</div>
					    <div className="col-6 mb-3">
					    	<label className="form-label">Tipo Servicio:</label>
					    	<Select 
			                  options={planes}
			                  getOptionValue={(option) => `${option['id']}`}
			                  getOptionLabel={(option) => `${option['desc_tipo_servicio']} - $${option['costo']}`}
			                  onChange={(selectedOption)=>handleChangeSelect(selectedOption, "tipo_plan_id")}
			                  value={data.tipo_plan_id}
			                  placeholder="Seleccione..."
			                />
					    </div>
					    <div className="col-6 mb-3">
					    	<label className="form-label">Tipo Pago:</label>
					    	<Select 
			                  options={catalogo.pagos}
			                  getOptionValue={(option) => `${option['id']}`}
			                  getOptionLabel={(option) => `${option['desc_tipo_pago']}`}
			                  onChange={(selectedOption)=>handleChangeSelect(selectedOption, "tipo_pago_id")}
			                  value={data.tipo_pago_id}
			                  placeholder="Seleccione..."
			                />
					    </div>
					    <div className="col-12 mb-3">
							  <label className="form-label">Dominio</label>
							  <input className="form-control" value={data.dominio}
							  	name="dominio" onChange={handleChangeText} />
					    </div>
					    <div className="col-6 mb-3">
							<label className="form-label">Fecha contratacion</label>
							<DatePicker locale="es" dateFormat="P" className="form-control" selected={data.fecha_contratacion} onChange={(date:Date) => handleChangeDate(date, "fecha_contratacion")} isClearable />
					    </div>
					    <div className="col-6 mb-3">
							<label className="form-label">Fecha pago</label>
							<DatePicker locale="es" dateFormat="P" className="form-control" selected={data.fecha_pago_cliente} onChange={(date:Date) => handleChangeDate(date, "fecha_pago_cliente")} isClearable />
					    </div>
					    <div className="col-6 mb-3">
							<label className="form-label">Fecha renovación</label>
							<DatePicker locale="es" dateFormat="P" className="form-control" selected={data.fecha_renovacion} onChange={(date:Date) => handleChangeDate(date, "fecha_renovacion")} isClearable />
					    </div>
					    <div className="col-6 mb-3">
							<label className="form-label">Fecha renovacion interno</label>
							<DatePicker locale="es" dateFormat="P" className="form-control" selected={data.fecha_renovacion_interno} onChange={(date:Date) => handleChangeDate(date, "fecha_renovacion_interno")} isClearable />
					    </div>
					    <div className="col-6 mb-3">
							  <label className="form-label">Precio contrato anual</label>
							  <input className="form-control" value={data.costo_contrato}
							  	name="costo_contrato" onChange={handleChangeText} />
					    </div>
					    <div className="col-6 mb-3">
							  <label className="form-label">Precio mensual</label>
							  <input className="form-control" value={data.costo_total_servicio}
							  	name="costo_total_servicio" onChange={handleChangeText} />
					    </div>
					    <div className="col-6 mb-3 mx-auto">
							  <label className="form-label">Costo Compra</label>
							  <input className="form-control" value={data.costo_compra}
							  	name="costo_compra" onChange={handleChangeText} />
					    </div>
					    <div className="col-12">
					    	<div className="mb-3">
							  <label className="form-label">Comentarios</label>
							  <textarea className="form-control" rows="4" value={data.comentario}
							  	name="comentario" onChange={handleChangeText}></textarea>
							</div>
							<button className="btn btn-success w-100" onClick={submitServicio}>Contratar servicio</button>
					    </div>
				    </div> : ""}
				  </Modal.Body>
	
		          {
		          	!loading ?
					<Modal.Footer>
						<Button variant="primary" onClick={handleClose}>
							Cerrar
						</Button>
					</Modal.Footer> : ""
		          }
				
				</Modal> : ""}
		</div>
	);
}

export default NuevoServicioPorEmpresa;