import React, {useState, useEffect, useRef} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Chart as ChartJS, ArcElement, Tooltip, Legend, Title} from 'chart.js';
import { Pie, Doughnut } from 'react-chartjs-2';
//import ChartDataLabels from 'chartjs-plugin-datalabels';
import GraficaPie from '../Graficas/GraficaPie';
import GraficaBar from '../Graficas/GraficaBar';

import {Get,Post} from '../../../Tools/Helper';

import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';

import GraficaVencidos from './GraficaVencidos';

function Index({setLoading}){

	ChartJS.register(ArcElement, Tooltip, Legend, Title);

	let initialRef = useRef(React.createRef());

	const [status, setStatus] = useState({status: null, message: ""});

	const [labels, setLabels] = useState([]);
	const [costos, setCostos] = useState([]);
	const [servicios, setServicios] = useState([]);
	const [colores, setColores] = useState([]);

	const [numeros, setNumeros] = useState({totalCosto: null, totalPrecio: null, utilidad: null});
	const [financieroTabla, setFinancieroTabla] = useState([]);

	const [vencidosData, setVencidosData] = useState([]);
	const [vencidosLabel, setVencidosLabel] = useState([]);

	const [vencidosData2, setVencidosData2] = useState([]);
	const [vencidosLabel2, setVencidosLabel2] = useState([]);

	useEffect(() => {

		const getLista = async e =>{
    
			setStatus({status: null, message: ""});
			setLoading(true);
		
			//const numData = await Get('financiero');
			//setNumeros(numData);
			const tablas = await Get('financiero3');
			setFinancieroTabla(tablas);
			const num = tablas.length - 1;
			setNumeros({totalCosto: tablas[num].ventas, totalPrecio: tablas[num].costos, utilidad: tablas[num].utilidad});

			setLoading(false);

			//const submit = await Get('servicio-cliente');

				let labels = [];
				let costos = [];
				let servicios = [];
				let colores = [];

				setStatus({status: null, message: ""});
				tablas.forEach((serv)=>{

					if(serv.nombre !== "TOTAL"){
						const randomBetween = (min, max) => min + Math.floor(Math.random() * (max - min + 1));
						const r = randomBetween(0, 255);
						const g = randomBetween(0, 255);
						const b = randomBetween(0, 255);
	
				    labels.push(serv.nombre);
				    costos.push(serv.utilidad);
				    servicios.push(serv.servicios_asignados);
				    colores.push("rgba(" + r + "," + g + ","+ b + ",1)");
				  }
				});

				setLabels(labels);
				setCostos(costos);
				setServicios(servicios);
				setColores(colores);

				const submit2 = await Get('perdidas');
				setVencidosData(submit2.dataset);
				setVencidosLabel(submit2.labels);

				const submit3 = await Get('perdidas2');
				setVencidosData2(submit3.dataset);
				setVencidosLabel2(submit3.labels);

		}

		getLista();

		if(initialRef.current){
			initialRef.current.scrollIntoView({ 
		       behavior: "smooth", 
		    });
		}

	}, []);

	const options = {
    plugins: {
      legend: {
        display: true,
        position: "right",
        align: "center",
        labels: {
          usePointStyle: true,
        },
        padding:"2rem",
      },
    },
    options:{
    	scales: {
        y: [{
          ticks: {
          	callback: function(value, index, values) {
	             return formatter.format(value);
	           }
          }
        }]
      }
    }
  };


  const opt = {

        yAxes: [{
        	id:'y',
          ticks: {
          	callback: function(value, index, values) {
	             return formatter.format(value);
	           }
          }
        }]
      
    };

  const formatter = new Intl.NumberFormat('en-US', {
	  style: 'currency',
	  currency: 'USD',

	  // These options are needed to round to whole numbers if that's what you want.
	  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
	  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
	});

  	const columns = [
	    {
	        name: 'Tipo Servicio',
	        selector: row => row.nombre,
	        cellExport: row => row.nombre,
	        grow:1
	    },
	    {
	        name: 'Clientes Asignados',
	        selector: "clientes_asignados",
	        grow:1
	    },
	    {
	        name: 'Serv. Asignados',
	        selector: "servicios_asignados",
	        grow:1
	    },
	    {
	        name: 'Ventas',
	        selector: row => formatter.format(row.ventas),
	        cellExport: row => formatter.format(row.ventas),
	        grow:1
	    },
	    {
	        name: 'Costos',
	        selector: row => formatter.format(row.costos),
	        cellExport: row => formatter.format(row.costos),
	        grow:1
	    },
	    {
	        name: 'Utilidad',
	        selector: row => formatter.format(row.utilidad),
	        cellExport: row => formatter.format(row.utilidad),
	        grow:1
	    },
	    {
	        name: 'Porcentaje',
	        selector: row => `${row.porcentaje.toFixed(2)}%`,
	        cellExport: row => `${row.porcentaje.toFixed(2)}%`,
	        grow:1
	    },
	];

	const customStyles = {
    headCells: {
      style: {
          backgroundColor: '#3664c7',
          color: '#ffffff',
          fontWeight: 'bold',
          fontSize: '1rem'
      },
    },
    table: {
    	style:{
    		borderWidth: "1px",
    		borderColor: "#3664c7",
    		borderStyle: 'solid',
    	}
    },
	};

	const conditionalRowStyles = [{
	    when: row => row.nombre === "TOTAL",
	    style: {
	      backgroundColor: '#3664c7',
	      color: 'white',
	      '&:hover': {
	        cursor: 'pointer',
	      },
	    },
  	}];

	const data = financieroTabla;

	const tableData = {
	    columns,
	    data,
	};


	const columns2 = [
	    {
	        name: 'Cliente',
	        selector: row => row.nombre_cliente,
	        cellExport: row => row.nombre_cliente,
	        grow:1
	    },
	    {
	        name: 'Contratacion',
	        selector: row => row.fecha_contratacion,
	        cellExport: row => row.fecha_contratacion,
	        grow:1
	    },
	    {
	        name: 'Renovacion',
	        selector: row => row.fecha_renovacion,
	        cellExport: row => row.fecha_renovacion,
	        grow:1
	    },
	    {
	        name: 'Precio Mensual',
	        selector: row => row.costo_total_servicio,
	        cellExport: row => row.costo_total_servicio,
	        grow:1
	    },
	    {
	        name: 'Precio Contrato Anual',
	        selector: row => row.costo_contrato,
	        cellExport: row => row.costo_contrato,
	        grow:1
	    },
	    {
	        name: 'Acumulado Restante',
	        selector: row => row.total_acumulado,
	        cellExport: row => row.total_acumulado,
	        grow:1
	    },
	    {
	        name: 'Costo Compra',
	        selector: row => row.costo_compra,
	        cellExport: row => row.costo_compra,
	        grow:1
	    },
	    
	];

	const ExpandedComponent = ({ data }) => <DataTable columns={columns2} data={data.servicios} />;
	
	return(
		<>
			<div className="title p-4" ref={initialRef}>
				<h3>Analitico Financiero Pendiente</h3>
			</div>

			<div className="container-fluid">
				<div className="row p-3">
					<div className="col-12 col-lg-6 col-md-4 mx-auto pb-4">
						<div className="card border-left-blue">
							<div className="card-header header-blue no-radius">
								<h5 className="mb-0 fw-bold py-1 text-center">Ventas de productos activos</h5>
							</div>
							<div className="card-body text-center my-auto">
								<h3>{numeros.totalCosto ? formatter.format(numeros.totalCosto) : "Cargando..."}</h3>
							</div>
						</div>
					</div>
				<div className="col-12 col-lg-6 col-md-4 mx-auto pb-4">
					<div className="card border-left-red">
						<div className="card-header header-red no-radius">
							<h5 className="mb-0 fw-bold py-1 text-center">Costos de productos activos</h5>
						</div>
						<div className="card-body text-center my-auto">
							<h3>{numeros.totalPrecio ? formatter.format(numeros.totalPrecio) : "Cargando..."}</h3>
						</div>
					</div>
				</div>
				<div className="col-12 col-lg-6 col-md-4 mx-auto pb-4">
					<div className="card border-left-green">
						<div className="card-header header-green no-radius">
							<h5 className="mb-0 fw-bold py-1 text-center">Utilidades</h5>
						</div>
						<div className="card-body text-center my-auto">
							<h3>{numeros.utilidad ? formatter.format(numeros.utilidad) : "Cargando..."}</h3>
						</div>
					</div>
				</div>
				<div className="col-12">
					
						<DataTableExtensions {...tableData}
							export={true}
							print
							exportHeaders={true}
							fileName={"Reporte"}
				        	filterPlaceholder="Buscar..."
				         >
						<DataTable
		            striped
		            customStyles={customStyles}
		            noDataComponent=<div className="p-5">No se han encontrado datos.</div>
		            conditionalRowStyles={conditionalRowStyles}
		            expandableRows expandableRowsComponent={ExpandedComponent}
		        />
		        </DataTableExtensions>
					
				</div>
			</div>

			<div className="row p-3">
				<div className="col-12 col-md-6">
					<div className="card">
						<div className="card-header">
							<h5 className="mb-0 fw-bold py-1 text-center">Total de clientes por servicio</h5>
						</div>
						<div className="card-body text-center my-auto">
							<GraficaPie 
								colores={colores}
								data={servicios}
								labels={labels}
								options={options}
								/>
						</div>
					</div>
				</div>
				<div className="col-12 col-md-6">
					<div className="card">
						<div className="card-header">
							<h5 className="mb-0 fw-bold py-1 text-center">Total de costos por servicio</h5>
						</div>
						<div className="card-body text-center my-auto">
							<GraficaPie 
								colores={colores}
								data={costos}
								labels={labels}
								options={options}
								/>
						</div>
					</div>
				</div>

				<div className="col-12 mt-4">
					<div className="card">
						<div className="card-header">
							<h5 className="mb-0 fw-bold py-1 text-center">Monto vencido por mes en 1 año</h5>
						</div>
						<div className="card-body text-center my-auto">
							<GraficaVencidos 
								dataset={vencidosData}
								labels={vencidosLabel}
								options={opt}
								/>
						</div>
					</div>
				</div>

				<div className="col-12 mt-4">
					<div className="card">
						<div className="card-header">
							<h5 className="mb-0 fw-bold py-1 text-center">Monto vencido por mes en 1 año</h5>
						</div>
						<div className="card-body text-center my-auto">
							<GraficaVencidos 
								dataset={vencidosData2}
								labels={vencidosLabel2}
								options={{
									


								}}
								/>
						</div>
					</div>
				</div>
				
			</div>
		</div>
		</>
	);
}

export default Index;