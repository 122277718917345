import React from 'react';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

function GraficaPie({colores, data, labels, col, options, label}){

	ChartJS.register(
	  CategoryScale,
	  LinearScale,
	  BarElement,
	  Title,
	  Tooltip,
	  Legend
	);

	const graphData = {
	  labels: labels,
	  datasets: [
	    {
	      label: label,
	      data: data,
	      borderWidth: 1,
	      backgroundColor: colores
	    }
	  ],
	};

	return(
		<Bar data={graphData} options={options} />
	);
}

export default GraficaPie;