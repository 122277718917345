import axios from 'axios';
const base = process.env.REACT_APP_API;
const token = localStorage.getItem('userData') ? JSON.parse(localStorage.getItem('userData')).access_token : '';

console.log('API:', base);

export async function Post(path,obj) {
	const headers = {
		'Authorization': "Bearer " + token,
		'Content-Type': 'application/json' 
	}

	console.log("POST: " + path);
	console.log("DATA: ");
	console.log(obj);

	let data = "";

	await axios.post((base + path), obj, {
	    headers: headers
	  })
	  .then((response) => {
	    console.log("RESPONSE:");
		console.log(response.data);
		data = response.data;
	  })
	  .catch((error) => {
	    console.log("RESPONSE:");
		console.log(error.response.data);
		data = error.response.data;
	  })

	  return data;
}

export async function Get(path) {
	const headers = {
		'Authorization': "Bearer " + token,
		'Content-Type': 'application/json' 
	}

	console.log("GET: " + path);

	let data = "";

	await axios.get((base + path), {
	    headers: headers
	  })
	  .then((response) => {
	    console.log("RESPONSE:");
		console.log(response.data);
		data = response.data;
	  })
	  .catch((error) => {
	    data = error;
	  })

	  return data;
}

export async function Put(path,obj) {

	const headers = {
		'Authorization': "Bearer " + token,
		'Content-Type': 'application/json' 
	}

	console.log("POST: " + path);
	console.log("DATA: ");
	console.log(obj);

	let data = "";

	await axios.put((base + path), obj, {
	    headers: headers
	  })
	  .then((response) => {
	    console.log("RESPONSE:");
		console.log(response.data);
		data = response.data;
	  })
	  .catch((error) => {
	    console.log("RESPONSE:");
		console.log(error.response.data);
		data = error.response.data;
	  })

	  return data;
}

export async function OldPost(path,obj){

	let isSubscribed = true;

	try{

		const requestOptions = {
			method: 'POST',
			headers: { 
				'Authorization': "Bearer " + token,
				'Content-Type': 'application/json' 
			},
			body: JSON.stringify(obj)
		};

		console.log("POST: " + path);
		console.log("DATA: ");
		console.log(obj);

		let response = await fetch(base+path, requestOptions);

		if (isSubscribed) {
			let data = await response.json();
			console.log("RESPONSE:");
			console.log(data);
			return data;
		}

	}catch(err){
		return {status:'error', message: err};
	}

	return () => {isSubscribed = false};

}

export async function OldPut(path,obj){

	let isSubscribed = true;

	try{

		const requestOptions = {
			method: 'PUT',
			headers: { 
				'Authorization': "Bearer " + token,
				'Content-Type': 'application/json' 
			},
			body: JSON.stringify(obj)
		};

		console.log("PUT: " + path);
		console.log("DATA: ");
		console.log(obj);

		let response = await fetch(base+path, requestOptions);

		if (isSubscribed) {
			let data = await response.json();
			console.log("RESPONSE:");
			console.log(data);
			return data;
		}

	}catch(err){
		return {status:'error', message: err};
	}

	return () => {isSubscribed = false};

}

export async function OldGet(path){

	let isSubscribed = true;

	try{
		
		const requestOptions = {
			method: 'GET',
			headers: { 
				'Authorization': "Bearer " + token,
				'Content-Type': 'application/json' 
			}
		};

		console.log("POST: " + path);

		let response = await fetch(base+path, requestOptions);

		if (isSubscribed) {
			let data = await response.json();
			console.log("RESPONSE:");
			console.log(data);
			return data;
		}

	}catch(err){
		return {status:'error', message: err};
	}

	return () => {isSubscribed = false};
}