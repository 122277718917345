import React from 'react';

function Loading({loading}){

	return(
		loading === true ?
		<div className="loading-box d-flex">
			<div className="loading-mini-box px-5 py-4 align-self-center mx-auto text-center">
			  <div className="spinner-border" role="status" aria-hidden="true"></div>
			  <h4 className="pt-2"><span className="fw-bold">Cargando...</span></h4>
			</div>
		</div> : ""
	);
}

export default Loading;