import React, {useState, useEffect} from 'react';
import TarjetaServicio2 from '../../Tarjetas/TarjetaServicio2';

import {Get} from '../../../../Tools/Helper';
import ModalServicio from './ModalServicio';

function TipoServicios({setLoading}){

	const [status, setStatus] = useState({status: null, message: ""});
	const [data, setData] = useState([]);

	useEffect(() => { 

		const getTipoServicios = async e =>{
    
			setStatus({status: null, message: ""});
			setLoading(true);
			const submit = await Get('tipo-servicio');

			if(submit.code === 200){
				setStatus({status: null, message: ""});
				setData(submit.data)
			}else{
				setStatus(submit.status);
			}
			
			setLoading(false);
		}

		getTipoServicios();

	}, []);

	const listaTipoServicios = data.length !== 0 ? data.map((tipo, index) => {
		return(
			<TarjetaServicio2 data={tipo} key={index} />
		)
	}) : "";

	/* -- MODAL DE NUEVO TIPO --*/
	const [show, setShow] = useState(false);

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	/* -- -- */

	return(
		<div className="container-fluid">
			<div className="row p-2">
				<div className="col-12 pb-3">
					<button className="btn btn-primary mt-3" onClick={handleShow}>Nuevo Servicio</button>
				</div>
			
				<div className="col-12">
					<div className="row lista-servicios">
						{listaTipoServicios}
					</div>
				</div>
			</div>
			<ModalServicio show={show} handleClose={handleClose} />
		</div>
	);
}

export default TipoServicios;