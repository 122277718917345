import React, {useState, useEffect} from 'react';

import {Put} from '../../../../Tools/Helper';
import StatusBar from '../../../../Tools/StatusBar';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faPenToSquare, } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function TarjetaPlan({data}){

	library.add(faPenToSquare);

	const [datosPlan,setDatosPlan] = useState(data);

	useEffect(() => { 
		setDatosPlan(data);
		setDataForm(data);
	}, [data, setDatosPlan]);

	const [editar,setEditar] = useState(false);
	const [dataForm,setDataForm] = useState({desc_tipo_servicio: data.desc_tipo_servicio, costo:data.costo});

	const [status, setStatus] = useState({status: null, message: ""});
	const [loading, setLoading] = useState(false);

	const handleFormChange = e => {
		setDataForm({...dataForm, 
				[e.target.name]: e.target.value
			});
	}

	const edit = async() => {

		setStatus({status: null, message: ""});
		setLoading(true);

		const submit = await Put('tipo-plan/'+data.id, dataForm);

		if(submit.code === 200){
			setStatus({status:'success', message:'Se ha guardado la información.'});
			setEditar(false);
			setDatosPlan(submit.data);
		}else{
			setStatus({...submit, status: 'danger'});
		}
		
		setLoading(false);
		setEditar(false);
	};

	const handleChangeImage = (evt) =>{
	    //console.log("Uploading");
	    var reader = new FileReader();
	    var file = evt.target.files[0];

	    reader.onload = function(upload) {
		    setDataForm({...dataForm, 
				imagen: upload.target.result
			});
		};
		reader.readAsDataURL(file);
	}

	return(
		<div className="col-12 col-md-6 col-xl-4 py-1">
			<div className="card h-100">
				<div className="ps-3 pe-4 py-2 my-auto">
					{!loading ? <div className="row my-auto">
						<div className="col-10 my-auto">
							{editar ? <input type="text" name="desc_tipo_servicio" placeholder="" 
								className="form-control new-box" value={dataForm.desc_tipo_servicio} onChange={handleFormChange} />
							: <h5 className="my-auto">{datosPlan.desc_tipo_servicio}</h5> }
						</div>
						<div className="col-2">
							<button className="ms-auto my-auto btn btn-outline-secondary" onClick={()=>setEditar(!editar)}><FontAwesomeIcon icon="fa-solid fa-pen-to-square" /></button>
						</div>
					</div> 
					: <div className="col-12 text-center py-3"><div className="spinner-border mx-auto my-auto" role="status">
					  		<span className="visually-hidden">Loading...</span>
						</div></div> }
					
					
					</div>
				
					{ (editar && !loading) ? 
						<div className="px-3 py-2 text-center text-lg-start d-flex flex-column">
						
						<div className="row">
						<div className="col-6">
							<input type="text" name="costo" placeholder="$" 
								className="form-control new-box" value={dataForm.costo} onChange={handleFormChange} />
						</div>
						<div className="col-6">
							<button className="btn btn-success px-2 mx-1 w-100" onClick={()=>edit()}>
								Guardar
							</button>
						</div></div>

					</div>
					: 	!loading ? <div className="px-3">Costo: {dataForm.costo}</div> : ""}
					<div className="row px-3 pt-2">
						<div className="col-12">
							<StatusBar status={status} />
						</div>
					</div>
						
			</div>
		</div>
	);
}

export default TarjetaPlan;