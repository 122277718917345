import React, {useState, useEffect} from 'react';

import { Modal, Button } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';

import {Post} from '../../../../Tools/Helper';
import StatusBar from '../../../../Tools/StatusBar';

function ModalServicio({show, handleClose}){

	library.add(faTriangleExclamation);

	const [dataForm,setDataForm] = useState({desc_servicio: "", imagen:""});
	const [loading,setLoading] = useState(false);
	const [status,setStatus] = useState({status: null, msg: ""});

	const handleFormChange = e => {
		setDataForm({...dataForm, 
			[e.target.name]: e.target.value
		});
	}

	const handleChangeImage = (evt) =>{
	    //console.log("Uploading");
	    var reader = new FileReader();
	    var file = evt.target.files[0];

	    reader.onload = function(upload) {
		    setDataForm({...dataForm, 
				imagen: upload.target.result
			});
		};
		reader.readAsDataURL(file);
	}

	const create = async() =>{

		setStatus({status: null, message: ""});
		setLoading(true);;

		const submit = await Post('tipo-servicio', dataForm);

		if(submit.code === 201){
			setStatus({status:'success', message:'Se ha capturado el servicio con exito.'});
		}else{
			setStatus({...submit, status: 'danger'});
		}
		
		setLoading(false);
	}

	const close = () =>{
	    setDataForm({desc_servicio: "", imagen:""});
	    handleClose();
	}

	return(
		<div>
			<Modal show={show} onHide={handleClose} backdrop="static">
			  <Modal.Header closeButton>
			    <Modal.Title>Crear nuevo servicio</Modal.Title>
			  </Modal.Header>
	
			  <Modal.Body className="">
			  	<div className="row">
			  		<div className="col-12">
			  			<StatusBar status={status} />
			  		</div>
			  		{(status.status !== "success" && loading === false) ? <><div className="col-3 my-auto text-center">
			  			<img src={dataForm.imagen} className="img-fluid" />
					</div>
			  		<div className="col-9 mb-3">
			  			<label className="form-label">Nombre Servicio:</label>
			  			<input type="text" name="desc_servicio" placeholder="" 
							className="form-control new-box px-2" value={dataForm.desc_servicio} onChange={handleFormChange} />
					</div>
					<div className="col-12 mb-3">
						<label className="form-label">Imagen:</label>
						<input className="form-control px-2" type="file" id="formFile" onChange={handleChangeImage} />
					</div></> : ""}
					{ loading ? 
			  		<div className="col-12 text-center"><div className="spinner-border mx-5 mt-5" role="status">
				  		<span className="visually-hidden">Loading...</span>
					</div>
					<h5 className="mb-5">Cargando...</h5>
					</div> : ""}
				</div>
			  </Modal.Body>
	
			  <Modal.Footer>
			  	<div className="row w-100">
			  		<div className="col-6 mb-3">
			  			{loading ? "" : 
			  			<Button variant="primary" onClick={close} className="w-100">
			        		Cerrar
			        	</Button>}
			  		</div>
			  		<div className="col-6 mb-3">
			        	{(status.status !== "success" && loading === false) ? 
			        		<button className="btn btn-success px-2 w-100" onClick={()=>create()}>
								Crear
							</button> : ""}
			  		</div>
				</div>
			  </Modal.Footer>
			</Modal>
		</div>
	);
}

export default ModalServicio;