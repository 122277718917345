import React, {useState, useEffect} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faPenToSquare, faFloppyDisk, faArrowLeft} from '@fortawesome/free-solid-svg-icons';

import {Put} from '../../../Tools/Helper';
import StatusBar from '../../../Tools/StatusBar';

import {Link} from "react-router-dom";

function TarjetaCliente({cliente, handleShow, handleShowNuevoservicio}){

	library.add(faPenToSquare,faFloppyDisk,faArrowLeft);

	const today = new Date();
	const [datosCliente,setDatosCliente] = useState(cliente);
	let totalActivos = 0;

	const [editar,setEditar] = useState(false);
	const [dataForm,setDataForm] = useState({nombre_contacto: cliente.nombre_contacto, 
		telefono_contacto:cliente.telefono_contacto});

	const [status, setStatus] = useState({status: null, message: ""});
	const [loading, setLoading] = useState(false);

	const handleFormChange = e => {
		setDataForm({...dataForm, 
				[e.target.name]: e.target.value
			});
	}

	useEffect(() => { 
		setDatosCliente(cliente);
	}, [cliente,setDatosCliente]);

	const servicios = cliente.servicios.map((s, index) =>{
		let dateCompare = new Date(s.fecha_vencimiento_interno);

		let enUnMes = new Date();
		enUnMes.setDate(enUnMes.getDate() + 30);

		if(dateCompare > today){
			totalActivos++;
		}

		function getType(date, status){
			if(status === 3){
				return "suspended";
			}

			if(date < today){
				return "btn-danger";
			}else{
				if(date < enUnMes){
					return "btn-warning";
				}
			}
			return "btn-light-3";
			
		}

		return(
			<button type="button" className={"btn m-1 " + getType(dateCompare, s.status)} data-bs-toggle="tooltip" 
				data-bs-placement="top" title={s.tipo_plan.tipo_servicio.desc_servicio}
				onClick={()=>{handleShow(s)}} key={index}>
			  <img className="img-fluid logo-serv align-middle d-inline-block pe-2" src={s.tipo_plan.tipo_servicio.imagen} />
			  {s.tipo_plan.desc_tipo_servicio}{s.dominio ? (": " + s.dominio) : ""}
			</button>
			
			)						
	});

	const submitServicio = async() => {

		setStatus({status: null, message: ""});
		setLoading(true);

		const submit = await Put('clientes/'+datosCliente.id, dataForm);

		if(submit.code === 200){
			setStatus({status:'success', message:'Se ha guardado la información.'});
			setEditar(false);
			setDatosCliente(submit.data);
		}else{
			setStatus({...submit, status: 'danger'});
		}
		
		setLoading(false);
	};

	return(
		<div className="col-12 col-lg-6 mb-0 my-3 appear appear" key={datosCliente.nombre_cliente}>
			<div className={"card h-100"}>
				<div className={"d-flex card-header"}>
					<div className="col d-flex">
						<FontAwesomeIcon icon="fa-solid fa-address-card" className="py-2 pe-2 icon" /><Link to={'/clientes/'+datosCliente.id}><h5 className="my-1">{datosCliente.nombre_cliente}</h5></Link>
					</div>
					<div className="col-auto text-right my-1">
						<a href={"mailto:"+datosCliente.correo}>{datosCliente.correo}</a>
					</div>
				</div>
				
				<div className="card-body p-3 d-flex flex-column">
					<StatusBar status={status} />
					{ !loading ? <>
										<div className="row">
											<div className="col-12">
												<h3 className="btn btn-success">Total Activos: {totalActivos}</h3>
											</div>
											<div className="col-12">
												<div className="mt-auto">
													<h4>Contacto</h4>
													<div className="row">
														{
															editar ?
															<><div className="col-6 pb-2">
																<span className="fw-bold">Nombre:</span> 
																<input type="text" name="nombre_contacto" placeholder="" className="form-control  new-box"
								                  					value={dataForm.nombre_contacto} onChange={handleFormChange} />
															</div>
															<div className="col-6 pb-2">
																<span className="fw-bold">Telefono:</span>
																<input type="text" name="telefono_contacto" placeholder="" className="form-control  new-box"
								                  					value={dataForm.telefono_contacto} onChange={handleFormChange} />
															</div></>
														: 	<><div className="col-6 pb-2">
																<p className="mb-0"><span className="fw-bold">Nombre:</span></p><p> {datosCliente.nombre_contacto}</p>
															</div>
															<div className="col-6 pb-2">
																<p className="mb-0"><span className="fw-bold">Telefono:</span></p><p> {datosCliente.telefono_contacto}</p>
															</div></>
														}
													</div>
													
													</div>
											</div>
											<div className="col-12">
												<div className="pb-3 my-auto">
												<h4>Servicios</h4>
													{servicios}
												</div>
											</div>
										</div>
										<div className="row mt-auto">
											<hr className="mt-0" />
											<div className="row">
												{ editar ? 
													<><div className="col-6">
														<button className="btn btn-danger px-2 mx-1 w-100" onClick={()=>setEditar(!editar)}>
															<FontAwesomeIcon icon="fa-solid fa-arrow-left" className="pe-2" /> Cancelar
														</button>
													</div>
													<div className="col-6">
														<button className="btn btn-success px-2 mx-1 w-100" onClick={()=>submitServicio()}>
															<FontAwesomeIcon icon="fa-solid fa-floppy-disk" className="pe-2" /> Guardar
														</button>
													</div></> 
												: 	<><div className="col-6">
														<button className="btn btn-primary px-2 mx-1 w-100" onClick={()=>setEditar(!editar)}>
															<FontAwesomeIcon icon="fa-solid fa-pen-to-square" className="pe-2" /> Editar
														</button>
													</div>
													<div className="col-6">
														<button className="btn btn-success px-2 mx-1 w-100" onClick={()=>handleShowNuevoservicio(datosCliente)}>
															Agregar Servicio
														</button>
													</div></>}
											</div>	
										</div>
										</> : <><div className="spinner-border mx-auto my-auto" role="status">
					  		<span className="visually-hidden">Loading...</span>
						</div>

						</> 
									}
				</div>
			</div>
		</div>
	);
}

export default TarjetaCliente;