import React, {useState} from 'react';

import {Put} from '../../../Tools/Helper';
import StatusBar from '../../../Tools/StatusBar';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faPenToSquare, } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function TarjetaServicio2({data}){

	library.add(faPenToSquare);

	const [datosServicio,setDatosServicio] = useState(data);

	const [editar,setEditar] = useState(false);
	const [dataForm,setDataForm] = useState({desc_servicio: data.desc_servicio, 
		imagen:data.imagen});

	const [status, setStatus] = useState({status: null, message: ""});
	const [loading, setLoading] = useState(false);

	const handleFormChange = e => {
		setDataForm({...dataForm, 
				[e.target.name]: e.target.value
			});
	}

	const edit = async() => {

		setStatus({status: null, message: ""});
		setLoading(true);

		const submit = await Put('tipo-servicio/'+data.id, dataForm);

		if(submit.code === 200){
			setStatus({status:'success', message:'Se ha guardado la información.'});
			setEditar(false);
			setDatosServicio(submit.data);
		}else{
			setStatus({...submit, status: 'danger'});
		}
		
		setLoading(false);
		setEditar(false);
	};

	const handleChangeImage = (evt) =>{
	    //console.log("Uploading");
	    var reader = new FileReader();
	    var file = evt.target.files[0];

	    reader.onload = function(upload) {
		    setDataForm({...dataForm, 
				imagen: upload.target.result
			});
		};
		reader.readAsDataURL(file);
	}

	return(
		<div className="col-12 col-md-6 col-xl-4 py-1">
			<div className="card h-100">
				<div className="px-4 py-2 my-auto">
					{!loading ? <div className="row my-auto">
						<div className="col-2 my-auto">
							<img src={dataForm.imagen} className="img-fluid" />
						</div>
						<div className="col-8 my-auto">
							{editar ? <input type="text" name="desc_servicio" placeholder="" 
								className="form-control new-box mx-2" value={dataForm.desc_servicio} onChange={handleFormChange} />
							: <h5 className="my-auto ps-2">{datosServicio.desc_servicio}</h5> }
						</div>
						<div className="col-2">
							<button className="ms-auto my-auto btn btn-outline-secondary" onClick={()=>setEditar(!editar)}><FontAwesomeIcon icon="fa-solid fa-pen-to-square" /></button>
						</div>
					</div> 
					: <div className="col-12 text-center py-3"><div className="spinner-border mx-auto my-auto" role="status">
					  		<span className="visually-hidden">Loading...</span>
						</div></div> }
					
					
					</div>
				
					{ (editar && !loading) ? 
						<div className="px-3 py-2 text-center text-lg-start d-flex flex-column">
						
						<div className="row">
						<div className="col-12">
							<input className="form-control" type="file" id="formFile" onChange={handleChangeImage} />
						</div>
						<div className="col-12 pt-3">
							<button className="btn btn-success px-2 mx-1 w-100" onClick={()=>edit()}>
								Guardar
							</button>
						</div></div>

					</div>
					: 	""}
					<div className="row px-3 pt-2">
						<div className="col-12">
							<StatusBar status={status} />
						</div>
					</div>
						
			</div>
		</div>
	);
}

export default TarjetaServicio2;