import React, {useState, useEffect, useRef} from 'react';
import {Post, Get} from '../../Tools/Helper';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faAddressCard, faFileArrowUp, faEraser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import ServicioModal from '../Modal/ServicioModal';

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';

import DatosServicio from './Tarjetas/DatosServicio';
import TarjetaServicio from './Tarjetas/TarjetaServicio';

function Servicios({setLoading}){

	library.add(faAddressCard);
	ChartJS.register(ArcElement, Tooltip, Legend);

	const [status, setStatus] = useState({status: null, message: ""});
	const [data, setData] = useState([]);
	const [count, setCount] = useState([]);
	const [servicios, setServicios] = useState(null);
	const [logo, setLogo] = useState(null);
	const [total, setTotal] = useState(0);

	let initialRef = useRef(React.createRef());

	useEffect(() => { 

		/*const getLista = async e =>{
    
			setStatus({status: null, message: ""});
			setLoading(true);
			const submit = await Post('total-servicios',[]);

			if(submit.code === 200){
				setStatus({status: null, message: ""});
				setData({labels: ['Mensual', 'Anual', 'Semestral'],
						datasets:[
							{
								label: 'Periodicidad',
								data: [submit.data[0].costo,submit.data[1].costo,submit.data[2].costo],
								backgroundColor: [
							        'rgba(255, 99, 132, 0.2)',
							        'rgba(54, 162, 235, 0.2)',
							    ]
							}
						]});
			}else{
				setStatus(submit.status);
			}
			
			setLoading(false);
		}*/

		const getLista = async e =>{
    
			setStatus({status: null, message: ""});
			setLoading(true);
			const submit = await Get('tipo-servicio');
			setTotal(submit.total);
			if(submit.code === 200){
				setStatus({status: null, message: ""});
				setData(submit.data)
				setCount(submit.count)
				setTotal(submit.total);
			}else{
				setStatus(submit.status);
			}
			
			setLoading(false);
			if(initialRef.current){
				initialRef.current.scrollIntoView({ 
			       behavior: "smooth", 
			    });
			}
		}

		getLista();

	}, []);

	const getServicios = async(tipo, logo) =>{
    
		setStatus({status: null, message: ""});
		setLoading(true);
		const submit = await Post('detalle-servicio-cliente2', {value:tipo});

		if(submit.code === 200){
			setStatus({status: null, message: ""});
			setLogo(logo);
			setServicios(submit.data);
		}else{
			setStatus(submit.status);
		}
		
		await setLoading(false);
		if(scrollRef.current){
			scrollRef.current.scrollIntoView({ 
		       behavior: "smooth", 
		    });
		}
	}

	const listaServicios = data.length !== 0 ? data.map((tipo, index) => {
		return(
			<TarjetaServicio tipo={tipo} getServicios={getServicios} key={index} count={count[index]} />
		)
	}) : "";

	const scrollRef = useRef(React.createRef());

	const customStyle = {
		paddingBottom: `${window.innerHeight - scrollRef.current.clientHeight}`,
	}

	  /* -- MODAL DE INFO SERVICIO --*/

	const [servicio, setServicio] = useState([]);
	const [show, setShow] = useState(false);

	const handleClose = () => setShow(false);
	const handleShow = async(servicio) => {
		setLoading(true);
		const data = await Get(`info-servicio/${servicio}`);
		setLoading(false);
		setServicio(data.data);
	};

	const [catalogo, setCatalogo] = useState([]);

	useEffect(()=>{
		setShow(true)
	},[servicio]);
	/* -- -- */

	return(
		<>
			<div className="title p-4" ref={initialRef}>
				<div className="row">
					<div className="col-6">
						<h3 className="d-inline">Lista de Servicios</h3>
					</div>
					<div className="col-6 text-center">
						<h3 className="d-inline border text-light bg-dark p-2 mx-4">Servicios: {data.length}</h3>
						<h3 className="d-inline border text-light bg-dark p-2 mx-4">Asignados: {total}</h3>
					</div>
				
				</div>
			</div>
			<div className="container-fluid">
				<div className="row p-2">
					{/*miembros*/}

					{/*data.length === 0 ? "" : <Pie data={data} />*/}
					<div className="col-12">
						<div className="row lista-servicios">
							{listaServicios}
						</div>
					</div>
				</div>
				<DatosServicio data={servicios} img={logo} scrollRef={scrollRef} customStyle={customStyle} handleShow={handleShow} />
			</div>

			<ServicioModal show={show} handleClose={handleClose} data={servicio} getLista={getServicios} catalogo={catalogo} />

		</>
	);
}

export default Servicios;