import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';

function StatusBar({status}){

	library.add(faTriangleExclamation);

	/*
	var errorList = "";
	if('errors' in status){		
		for(let error of Object.keys(status.errors)){
			errorList = errorList + status.errors[error] + "<br>";
		}
	}*/

	const errors =  'errors' in status ? 
		Object.keys(status.errors).map((e) =>
    		<div><FontAwesomeIcon icon="fa-solid fa-triangle-exclamation" className="pe-2" /> {status.errors[e]} <br/></div>) : "";

	return(
		status.status !== null ?
		<div className={"alert alert-" + status.status} role="alert">
			{errors}
			{status.message}
		</div> : ""
	);
}

export default StatusBar;