import React, {useRef, useEffect, useState} from 'react';
import { useParams } from "react-router-dom";

import {Get} from '../../Tools/Helper';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faChartLine, faBook, faUsers, faArrowRightFromBracket, faReceipt } from '@fortawesome/free-solid-svg-icons';

import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';

import {Link} from "react-router-dom";

function InfoEmpresa({setLoading}){

	let { idCliente } = useParams();
	let scrollRef = useRef(React.createRef());
	library.add(faChartLine);

	const [infoCliente, setInfoCliente] = useState([]);

	useEffect(()=>{
		setLoading(true);
		const getInfo = async() =>{
			const info = await Get('clientes/' + idCliente);

			if(info.code === 200){
				setInfoCliente(info.data);
			}
			setLoading(false);
		}

		getInfo();
	},[]);

	const columns = [
	    {
	        name: 'Servicio',
	        selector: "desc_servicio",
	        grow:2
	    },
	    {
	        name: 'Dominio',
	        selector: "dominio",
	        grow:2
	    },
	    {
	        name: 'Contratacion',
	        selector: "fecha_contratacion",
	        grow:1
	    },
	    {
	        name: 'Pago',
	        selector: "fecha_pago_cliente",
	        
	        grow:1
	    },
	    {
	        name: 'Vencimiento',
	        selector: "fecha_vencimiento_interno",
	        
	        grow:1
	    },
	    {
	        name: 'Renovacion',
	        selector: "fecha_renovacion",
	        
	        grow:1
	    },
	    {
	        name: 'Renovacion Interno',
	        selector: "fecha_renovacion_interno",

	        grow:1
	    },
	    {
	        name: 'P.Venta*',
	        selector: "costo",
	        grow:0
	    },
	    {
	        name: 'Comentario',
	        selector: "comentario",
	        grow: 4,
	        wrap: true,
	    },
	];

	const customStyles = {
	    headCells: {
	        style: {
	            backgroundColor: '#3664c7',
	            color: '#ffffff',
	            fontWeight: 'bold',
	            fontSize: '1rem'
	        },
	    },
	    table: {
	    	style:{
	    		borderWidth: "1px",
	    		borderColor: "#3664c7",
	    		borderStyle: 'solid',
	    	}
	    }
	};

	const data = infoCliente.servicios;

	const tableData = {
	    columns,
	    data,
	};

	return(
		<div className="sa">
			<div className="title p-4" ref={scrollRef}>
				<h3>Informacion de cliente: <span className="fw-bold">{infoCliente.nombre_cliente}</span></h3>
			</div>
			<div className="container-fluid p-4">
				<Link to="/clientes" className="btn btn-primary btn-lg mb-4">Regresar a Clientes</Link>
				<div className="card">
				  <div className="card-header">
				    <h5 className="mb-0 py-2 fw-bold">{infoCliente.nombre_cliente}</h5>
				  </div>
				  <div className="card-body">
				  	<div className="row">
				  		<div className="col-auto px-4 me-auto">
				  			<h5>Correo</h5>
				    		<p>{infoCliente.correo}</p>
				  		</div>
				  		<div className="col-auto px-4 mx-auto">
				  			<h5>Nombre Contacto</h5>
				   			<p>{infoCliente.nombre_contacto}</p>
				  		</div>
				  		<div className="col-auto px-4 mx-auto">
				  			<h5>Telefono Contacto</h5>
				    		<p>{infoCliente.telefono_contacto}</p>
				  		</div>
				  		<div className="col-auto px-4 ms-auto">
				  			<h5>Correo Contacto</h5>
				    		<p>{infoCliente.correo_contacto}</p>
				  		</div>
				  	</div>
				  </div>
				</div>
				<DataTableExtensions {...tableData}
					export={true}
					print={false}
					exportHeaders={true}
					fileName={"Reporte"}
		        	filterPlaceholder="Buscar..."

		         >
				<DataTable
		            striped
		            customStyles={customStyles}
		            noDataComponent=<div className="p-5">No se han encontrado datos.</div>
		        />
		        </DataTableExtensions>
		        <h5 className="pt-3 fw-bold text-end">*Precios mas I.V.A.</h5>
			</div>
		</div>
	);
}

export default InfoEmpresa;