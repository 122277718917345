import React, {useState, useEffect} from 'react';
import TarjetaPlan from './TarjetaPlan';

import {Get} from '../../../../Tools/Helper';
import ModalPlan from './ModalPlan';
import Select from 'react-select';

import StatusBar from '../../../../Tools/StatusBar';

function TipoPlanes({setLoading}){

	const [status, setStatus] = useState({status: null, message: ""});
	const [servicios, setServicios] = useState([]);
	const [planes, setPlanes] = useState([]);
	const [filteredPlanes, setFilteredPlanes] = useState([]);

	const [selectedServicio, setSelectedServicio] = useState(null);

	useEffect(() => { 

		const getTipoServicios = async e =>{
    
			setStatus({status: null, message: ""});
			setLoading(true);
			const submit = await Get('tipo-servicio');

			if(submit.code === 200){
				setServicios(submit.data);
				const submit2 = await Get('tipo-plan');
				if(submit2.code === 200){
					setPlanes(submit2.data);
				}else{
					setStatus(submit2.status);
				}
			}else{
				setStatus(submit.status);
			}
			setLoading(false);
		}

		getTipoServicios();

	}, []);


	const listaTipoServicios = filteredPlanes.length !== 0 ? filteredPlanes.map((tipo, index) => {
		return(
			<TarjetaPlan data={tipo} key={index}/>
		)
	}) : "";

	/* -- MODAL DE NUEVO TIPO --*/
	const [show, setShow] = useState(false);

	const handleClose = () => setShow(false);
	const handleShow = () => {
		if(selectedServicio !== null){
			setShow(true);
		}else{
			setStatus({status: 'danger', message: "Seleccione un tipo de servicio"});
		}
	};
	/* -- -- */

	const filterPlanes = (selectedOption) => {
		setStatus({status: null, message: ""});
		let tempArray = [];
		let tempPlanes = [...planes];

		//console.log(selectedOption);
		tempPlanes.forEach((el)=>{
			//console.log(el);
		    if(el.tipo_servicio_id == selectedOption.id){
		    	tempArray.push(el);
		    }
		    //console.log(el);

		    //console.log(selectedOption);

		});

		setFilteredPlanes(tempArray);
		console.log(tempArray);
		setSelectedServicio(selectedOption);
	};

	return(
		<div className="container-fluid">
			
			<div className="row p-2">
				<div className="col-auto my-auto">
					<h4 className="mb-0 fw-bold">Servicio: </h4>
				</div>
				<div className="col pb-3">
					<Select 
		              options={servicios}
		              getOptionValue={(option) => `${option['id']}`}
		              getOptionLabel={(option) => `${option['desc_servicio']}`}
		              onChange={(selectedOption)=>filterPlanes(selectedOption)}
		              value={selectedServicio}
		              placeholder="Seleccione..."
		              className="pt-3"
		            />
				</div>
				<div className="col-12 col-md-3 pb-3">
					<button className="btn btn-primary mt-3 w-100" onClick={handleShow}>Nuevo Servicio</button>
				</div>
			
				<div className="col-12">
					<StatusBar status={status} />
					<div className="row lista-servicios">
						{listaTipoServicios}
					</div>
				</div>
			</div>
			<ModalPlan show={show} handleClose={handleClose} servicio={selectedServicio} />
		</div>
	);
}

export default TipoPlanes;