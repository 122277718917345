import React, {useState, useEffect, useRef} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Chart as ChartJS, ArcElement, Tooltip, Legend, Title} from 'chart.js';
import { Pie, Doughnut } from 'react-chartjs-2';
//import ChartDataLabels from 'chartjs-plugin-datalabels';
import GraficaPie from './Graficas/GraficaPie';
import GraficaBar from './Graficas/GraficaBar';

import ServicioModal from '../Modal/ServicioModal';

import {Get,Post} from '../../Tools/Helper';

function Inicio({setLoading}){

	ChartJS.register(ArcElement, Tooltip, Legend, Title);

	let initialRef = useRef(React.createRef());

	const [status, setStatus] = useState({status: null, message: ""});

	const [porExpirar, setPorExpirar] = useState([]);
	const [filteredData, setFilteredData] = useState([]);
	const [expirados, setExpirados] = useState([]);
	const [todos, setTodos] = useState(0);

	const [conteoServicios, setConteoServicios] = useState({lista: [], labels:[], data:[]});
	const [conteoClientes, setConteoClientes] = useState({lista: [], labels:[], data:[]});

	function datefilter(date){
		let newDate = new Date(date);
		if(newDate){
	      let month = newDate.getUTCMonth();
	      let year = newDate.getUTCFullYear();
	      let day = newDate.getUTCDate();

	      let zeroDay = day < 9 ? "0" : "";
	      let zeroMonth = month < 9 ? "0" : "";

	      return zeroDay + day + '-' + zeroMonth + (month+1) + '-' + year;
      }
    return null;
  }

	useEffect(() => {

		const getLista = async e =>{
    
			setStatus({status: null, message: ""});
			setLoading(true);

			const submit3 = await Get('dashboard');
	    setPorExpirar(submit3.porExpirar);
	    setFilteredData(submit3.porExpirar);
	    setTodos(submit3.numTotal);

	    setConteoServicios({...conteoServicios, 
	    	lista:submit3.servicios,
	    	labels:submit3.nombre_servicio,
	    	data:submit3.total_servicio,
	    });
	    setConteoClientes({...conteoServicios, 
	    	lista:submit3.clientes,
	    	labels:submit3.nombre_cliente,
	    	data:submit3.total_cliente,
	    });

	    setLoading(false);
		    
		}

		getLista();

		if(initialRef.current){
			initialRef.current.scrollIntoView({ 
		       behavior: "smooth", 
		    });
		}

	}, []);

	const options = {
    plugins: {
      legend: {
        display: true,
        position: "bottom",
        align: "center",
        labels: {
          usePointStyle: true,
        },
        padding:"2rem",
      },
    }
  };


  /* -- MODAL DE INFO SERVICIO --*/

	const [servicio, setServicio] = useState([]);
	const [show, setShow] = useState(false);

	const handleClose = () => setShow(false);
	const handleShow = async(servicio) => {
		setLoading(true);
		const data = await Get(`info-servicio/${servicio.servicio_id}`);
		setLoading(false);
		setServicio(data.data);
	};

	const [catalogo, setCatalogo] = useState([]);

	const getLista = async e =>{
    
		setStatus({status: null, message: ""});
		setLoading(true);

		const submit3 = await Get('dashboard');
    setPorExpirar(submit3.porExpirar);
    setFilteredData(submit3.porExpirar);
    setTodos(submit3.numTotal);

    setConteoServicios({...conteoServicios, 
    	lista:submit3.servicios,
    	labels:submit3.nombre_servicio,
    	data:submit3.total_servicio,
    });
    setConteoClientes({...conteoServicios, 
    	lista:submit3.clientes,
    	labels:submit3.nombre_cliente,
    	data:submit3.total_cliente,
    });

    setLoading(false);
	    
	}

	useEffect(()=>{
		setShow(true)
	},[servicio]);
	/* -- -- */


	const listaPorExpirar = filteredData.length !== 0 ? filteredData.map((exp, index)=>{
		return <div className="col-xl-3 col-12 col-lg-4 mb-4" key={index}>
			<div className="alert alert-warning new-box h-100 b-anim" role="alert" onClick={()=>handleShow(exp)}>
				<div className="row h-100">
					<div className="col-6">
						<a href={`clientes/${exp.cliente_id}`}><span className="fw-bold">{exp.nombre_cliente}</span></a>
					</div> 
					<div className="col-6 text-end">
						<span className="fw-bold">{exp.fecha_renovacion}</span>
					</div> 
					<div className="col-12">
						{exp.desc_servicio}: {exp.desc_tipo_servicio}
						<hr className="my-1"/>
						{exp.dominio}
					</div> 
				</div> 
			</div> 
		</div> 
	}) : "";

	const listaExpirados = expirados.length !== 0 ? porExpirar.map((exp, index)=>{
		return <div className="col-xl-3 col-12 col-lg-4" key={index}>
			<div className="alert alert-warning" role="alert">
				<div className="row">
					<div className="col-8">
						<span className="fw-bold">{exp.nombre_cliente}</span>
					</div> 
					<div className="col-4">
						<span className="fw-bold">{exp.fecha_renovacion}</span>
					</div> 
					<div className="col-12">
						{exp.desc_servicio} : {exp.desc_tipo_servicio}
					</div> 
				</div> 
				
			</div> 
		</div> 
	}) : 
	<div className="col-xl-3 col-12 col-lg-4" role="alert">
		<div className="alert alert-success" role="alert">
			¡No hay servicios expirados!
		</div>
	</div>;

	const filterServicio = (tipo) => {

		setFilteredData([]);

		setTimeout(() => {
			if(tipo !== null){
				let tempArray = [];
			
				porExpirar.forEach((el)=>{
				    if(el.tipo_servicio_id == tipo){
				    	tempArray.push(el);
				    }
				});
					setFilteredData(tempArray);
			}else{
					setFilteredData(porExpirar);
			}
		}, "100");
	};

	const listaConteoServicios = conteoServicios.lista.length !== 0 ? conteoServicios.lista.map((elemento)=>{
		return <button className="btn btn-primary btn-lg m-1" key={elemento.id} onClick={()=>filterServicio(elemento.id)}>{elemento.nombre} 
				 <span className="badge bg-danger rounded-pill ms-1">{elemento.total}</span>
			</button>;
	}) : "";

	const today = new Date();
	
	return(
		<>
			<div className="title p-4" ref={initialRef}>
				<h3>Graficas de Servicios</h3>
			</div>
			<div className="card p-4 my-3">
				<h5 className="fw-bold pb-2">Servicios proximos a expirar: {datefilter(today)} a {datefilter(new Date(today.setMonth(today.getMonth()+1)))}</h5>
				<div className="row">
					<div className="col-12 pb-4">
						<button className="btn btn-primary btn-lg m-1" onClick={()=>filterServicio(null)}>
							Todos <span className="badge bg-danger rounded-pill ms-1">{todos}</span>
						</button>
						{listaConteoServicios}
					</div>
					<div className="col-12">
						<div className="row">
							{listaPorExpirar}
						</div>
					</div>
				</div>
			</div>

			<div className="card p-4 my-3">
				<h5 className="fw-bold pb-2">Servicios expirados</h5>
				<div className="row">
						{listaExpirados}
				</div>
			</div>

			<div className="card p-4 my-3">
				<h5 className="fw-bold pb-2">Graficas</h5>
				<div className="row">
					<div className="col-12 col-md-6 px-3">
						<h5 className="fw-bold pb-2 text-center">Servicios por Cliente</h5>
						<GraficaBar 
							colores={['#50de61']}
							data={conteoClientes.data}
							labels={conteoClientes.labels}
							options={options}
							label={'Servicios'}
							/>
					</div>
					<div className="col-12 col-md-6 px-3">
						<h5 className="fw-bold pb-2 text-center">Clientes por servicio</h5>
						<GraficaBar 
							colores={['#de5050']} 
							data={conteoServicios.data}
							labels={conteoServicios.labels}
							options={options}
							label={'Clientes'}
							/>
					</div>
				</div>
			</div>

			<ServicioModal show={show} handleClose={handleClose} data={servicio} getLista={getLista} catalogo={catalogo} />
			
		</>
	);
}

export default Inicio;