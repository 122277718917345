import React, {useState, useEffect, useRef} from 'react';
import {Get} from '../../Tools/Helper';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faAddressCard } from '@fortawesome/free-solid-svg-icons';

import ServicioModal from '../Modal/ServicioModal';
import TarjetaCliente from './Tarjetas/TarjetaCliente';

import NuevoServicioPorEmpresa from '../Modal/NuevoServicioPorEmpresa';
import NuevoClienteModal from '../Modal/NuevoClienteModal';
import Select from 'react-select';

function Clientes({setLoading}){

	library.add(faAddressCard);
	let scrollRef = useRef(React.createRef());

	const [status, setStatus] = useState({status: null, message: ""});
	const [data, setData] = useState([]);
	const [filteredData, setFilteredData] = useState([]);
	const [selectedCliente, setSelectedCliente] = useState(null);

	useEffect(() => { 
		getLista();
	}, []);

	/* -- MODAL DE INFO SERVICIO --*/

	const [servicio, setServicio] = useState([]);
	const [show, setShow] = useState(false);

	const handleClose = () => setShow(false);
	const handleShow = (servicio) => {
		setShow(true);
		setServicio(servicio);
	};

	/* -- -- */

	/* -- MODAL DE NUEVO SERVICIO --*/

	const [catalogo, setCatalogo] = useState([]);
	const [selectedEmpresa, setSelectedEmpresa] = useState(null);
	const [showNuevoServicio, setShowNuevoServicio] = useState(false);

	const handleCloseNuevoServicio = () => setShowNuevoServicio(false);
	const handleShowNuevoservicio = (empresa) => {
		setSelectedEmpresa({nombre:empresa.nombre_cliente, id:empresa.id});
		setShowNuevoServicio(true);
		
	};

	const filterEmpresa = (empresa = null) => {
		setSelectedCliente(empresa);

		if(empresa !== null){
			let tempArray = [];
		
			data.forEach((el)=>{
			    if(el.id == empresa.id){
			    	tempArray.push(el);
			    }
			});
	
			setFilteredData(tempArray);
		}else{
			setFilteredData(data);
		}
		console.log(empresa.valueOf());
	};

	/* -- -- */

	/* ---MODAL NUEVO CLIENTE---- */

	const [showNuevoCliente, setShowNuevoCliente] = useState(false);

	const handleCloseNuevoCliente = () => setShowNuevoCliente(false);
	const handleShowNuevoCliente = () => {
		setShowNuevoCliente(true);
	};

	/*------*/
	const miembros = filteredData != null ? filteredData.map((cliente, index) => {
		return <TarjetaCliente cliente={cliente} handleShow={handleShow} handleShowNuevoservicio={handleShowNuevoservicio} key={index} />
	}) : "";

	const getLista = async e =>{
		setStatus({status: null, message: ""});
		setLoading(true);
		const submit = await Get('clientes');

		if(submit.code === 200){
			setStatus({status: null, message: ""});
			setData(submit.data);
			setFilteredData(submit.data);

			const cat = await Get('catalogos');
			setCatalogo(cat);
		}else{
			setStatus(submit.status);
		}

		if(selectedCliente !== null) {
			filterEmpresa(selectedCliente);
		}
		
		setLoading(false);

		if(scrollRef.current){
			scrollRef.current.scrollIntoView({ 
			   behavior: "smooth", 
			});
		}
	}

	const filterExpirados = (empresa) => {
		console.log(data);
		setLoading(true);
		let tempArray = [];
		setFilteredData([]);
	
		const today = new Date();
		//console.log(selectedOption);
		data.forEach((el)=>{
			//console.log(el);
			let found = false;
			let tempServicios = [];
			el.servicios.forEach((servicio)=>{
				let dateCompare = new Date(servicio.fecha_vencimiento_interno);
				if(dateCompare < today){
					if(found === false){
						found = true;
					}
					tempServicios.push(servicio);
				}	
			});
			if(found){
				let tempEmpresa = {...el};
				tempEmpresa.servicios = tempServicios;
				tempArray.push(tempEmpresa);
			}
		});

		setTimeout(() => {
	      setLoading(false);
	      setFilteredData(tempArray);
	    }, "200");

	};

	const filterPorExpirar = (empresa) => {
		console.log(data);
		setLoading(true);
		let tempArray = [];
		setFilteredData([]);
	
		const today = new Date();
		const enUnMes = new Date();
		enUnMes.setDate(enUnMes.getDate() + 30);
		//console.log(selectedOption);
		data.forEach((el)=>{
			//console.log(el);
			let found = false;
			let tempServicios = [];
			el.servicios.forEach((servicio)=>{
				let dateCompare = new Date(servicio.fecha_vencimiento_interno);
				if((dateCompare < enUnMes) && (dateCompare > today)){
					if(found === false){
						found = true;
					}
					tempServicios.push(servicio);
				}	
			});
			if(found){
				let tempEmpresa = {...el};
				tempEmpresa.servicios = tempServicios;
				tempArray.push(tempEmpresa);
			}
		});

		setTimeout(() => {
	      setLoading(false);
	      setFilteredData(tempArray);
	    }, "200");
		
	};

	const fillEmpresas = () => {
		setLoading(true);
		console.log('fillEmpresas');
		console.log(data);
		//setFilteredData([]);
		setTimeout(() => {
	      setLoading(false);
	      setFilteredData(data);
	    }, "200");
	};

	return(
		<>
			<div className="title p-4" ref={scrollRef}>
				<div className="row">
					<div className="col-6">
						<h3 className="d-inline">Clientes</h3>
					</div>
					<div className="col-6 text-center">
						<h3 className="d-inline border text-light bg-dark p-2">Total: {data.length}</h3>
					</div>
				</div>
			</div>
			<div className="container-fluid">
				<div className="row p-2">
					<div className="col-12 col-lg-4">
						<h5 className="pt-2">Buscador:</h5>
						<Select 
							options={data}
							getOptionValue={(option) => `${option['id']}`}
							getOptionLabel={(option) => `${option['nombre_cliente']}`}
							onChange={(selectedOption)=>filterEmpresa(selectedOption)}
							value={selectedCliente}
							placeholder="Ingrese el nombre de la empresa..."
							isClearable={true}
						/>
						<button className="btn btn-success btn-lg mt-3" onClick={handleShowNuevoCliente} >Nuevo Cliente...</button>
					</div>

					<div className="col-12 col-lg-2 mt-2 mt-lg-auto ms-auto">
						<button className="btn btn-primary w-100"
							onClick={fillEmpresas}>
							TODOS
						</button>
					</div>
					<div className="col-12 col-lg-2 mt-2 mt-lg-auto">
						<button className="btn btn-warning w-100"
							onClick={filterPorExpirar}>
							POR EXPIRAR
						</button>
					</div>
					<div className="col-12 col-lg-2 mt-2 mt-lg-auto">
						<button className="btn btn-danger w-100"
							onClick={filterExpirados}>
							EXPIRADOS
						</button>
					</div>
				</div>
				<div className="row p-2">
					
					{miembros}
				</div>
			</div>
			<ServicioModal show={show} handleClose={handleClose} data={servicio} getLista={getLista} catalogo={catalogo} />
			<NuevoServicioPorEmpresa show={showNuevoServicio} handleClose={handleCloseNuevoServicio} 
				catalogo={catalogo} selectedEmpresa={selectedEmpresa} getLista={getLista} />
			<NuevoClienteModal show={showNuevoCliente} handleClose={handleCloseNuevoCliente} 
				getLista={getLista} />

		</>
	);
}

export default Clientes;