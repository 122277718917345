import React, {useState, useEffect} from 'react';

import { Modal, Button } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faTriangleExclamation, faCalendarPlus, faPenToSquare, faXmark } from '@fortawesome/free-solid-svg-icons';

import DatePicker from "react-datepicker";
import {Put,Post} from '../../Tools/Helper';
import StatusBar from '../../Tools/StatusBar';

import { registerLocale } from  "react-datepicker";
import es from 'date-fns/locale/es';

import Select from 'react-select';

function ServicioModal({data, show, handleClose, getLista, catalogo}){

	registerLocale('es', es);

	const [formData, setFormData] = useState(data);
	const [loading, setLoading] = useState(false);
	const [status, setStatus] = useState({status:null, message:''});

	useEffect(()=>{
		setFormData({...data, 
			fecha_contratacion: new Date(data.fecha_contratacion),
			fecha_pago_cliente: new Date(data.fecha_pago_cliente),
			fecha_renovacion: new Date(data.fecha_renovacion),
			fecha_renovacion_interno: new Date(data.fecha_renovacion_interno),
			fecha_vencimiento_interno: new Date(data.fecha_vencimiento_interno),
			costo_compra: data.costo_compra,
			costo_contrato: data.costo_contrato,
			costo_total_servicio: data.costo_total_servicio,
		});
	},[data, setFormData]);

	library.add(faTriangleExclamation, faCalendarPlus, faPenToSquare, faXmark);
	
	const [tipoTramite, setTipoTramite] = useState(0);
	/* 0: INFO, 1: RENOVAR, 2:EDITAR */

	const handleChange = (fecha, nombre) => {
		setFormData({...formData, [nombre]:fecha});
	};

	const handleChangeText = (e) => {
		setFormData({...formData, [e.target.name]:e.target.value});
	};

	const handleChangeSelect = (selectedOption, nombre) => {
		setFormData({...formData, [nombre]:selectedOption, tipo_pago_id:selectedOption.id});
		//console.log(data);
	};

	const reset = () =>{
		handleClose();
		setTipoTramite(0);
		setStatus({status:null, message:''});
	}

	const actionEditar = async() =>{
		setLoading(true);
		let tempArray = {...formData};

		function transformDate(dataObj){
			let year, month, day;

			year = dataObj.getFullYear();

			if(dataObj.getMonth() < 9){
				let temp = dataObj.getMonth() + 1;
				month = "0" + temp;
			}else{
				month = dataObj.getMonth() + 1;
			}

			if(dataObj.getDate() < 9){
				day = "0" + dataObj.getDate();
			}else{
				day = dataObj.getDate();
			}

			return (year+"-"+month+"-"+day+" 00:00:00");
		}

		tempArray.fecha_contratacion = transformDate(tempArray.fecha_contratacion);
		tempArray.fecha_pago_cliente = transformDate(tempArray.fecha_pago_cliente);
		tempArray.fecha_renovacion = transformDate(tempArray.fecha_renovacion);
		tempArray.fecha_renovacion_interno = transformDate(tempArray.fecha_renovacion_interno);
		tempArray.fecha_vencimiento_interno = transformDate(tempArray.fecha_vencimiento_interno);
		console.log(tempArray);

		const editarData = await Put('servicio/'+formData.id,tempArray);
		if(editarData.code === 200){
			await getLista();
			setStatus({status:'success', 'message': 'Se ha editado correctamente.'});
		}
		setLoading(false);
		setTipoTramite(4);

	}

	const actionRenovar = async() =>{
		setLoading(true);
		const editarData = await Post('renovar/'+formData.id,[]);
		if(editarData.code === 200){
			await getLista();
			setStatus({status:'success', 'message': 'Se ha editado correctamente.'});
		}
		setLoading(false);
		setTipoTramite(4);
	}

	const actionExpirar = async() =>{
		setLoading(true);
		const editarData = await Post('suspender/'+formData.id,[]);
		if(editarData.code === 200){
			await getLista();
			setStatus({status:'success', 'message': 'Se ha cambiado el estatus correctamente.'});
		}
		setLoading(false);
		setTipoTramite(4);
	}

	function datefilter(date){
		let newDate = new Date(date);
		if(newDate){
            let month = newDate.getUTCMonth();
            let year = newDate.getUTCFullYear();
            let day = newDate.getUTCDate();

            let zeroDay = day < 9 ? "0" : "";
            let zeroMonth = month < 9 ? "0" : "";

            return zeroDay + day + '-' + zeroMonth + (month+1) + '-' + year;
        }
        return null;
    }

	const info = <div className="row appear">
				    <div className="col-6 pb-2">
				    	<h5 className="mb-0 fw-bold">Contratación</h5>
				    	<h5>{datefilter(formData.fecha_contratacion)}</h5>
				    </div>
				    <div className="col-6 pb-2">
				    	<h5 className="mb-0 fw-bold">Pago cliente</h5>
				    	<h5>{datefilter(formData.fecha_pago_cliente)}</h5>
				    </div>
				    <div className="col-6 pb-2">
				    	<h5 className="mb-0 fw-bold">Renovación (Reporte)</h5>
				    	<h5>{datefilter(formData.fecha_renovacion)}</h5>
				    </div>
				    <div className="col-6 pb-2">
				    	<h5 className="mb-0 fw-bold">Renovación interno</h5>
				    	<h5>{datefilter(formData.fecha_renovacion_interno)}</h5>
				    </div>
				    <div className="col-6 pb-2">
				    	<h5 className="mb-0 fw-bold">Vencimiento interno</h5>
				    	<h5>{datefilter(formData.fecha_vencimiento_interno)}</h5>
				    </div>
				    <div className="col-6 pb-2">
				    	<h5 className="mb-0 fw-bold">Tipo Pago</h5>
				    	<h5>{formData.tipo_pago ? data.tipo_pago.desc_tipo_pago : ""}</h5>
				    </div>
				    <div className="col-6 pb-2">
				    	<h5 className="mb-0 fw-bold">Precio Contrato Anual</h5>
				    	<h5>{formData.costo_contrato}</h5>
				    </div>
				    <div className="col-6 pb-2">
				    	<h5 className="mb-0 fw-bold">Costo Anual</h5>
				    	<h5>{formData.costo_compra}</h5>
				    </div>
				    <div className="col-6 pb-2">
				    	<h5 className="mb-0 fw-bold">Pago mensual (Sin IVA)</h5>
				    	<h5>{formData.costo_total_servicio}</h5>
				    </div>
				    <div className="col-12 pb-2">
				    	<hr className="mt-0" />
				    	<h5 className="mb-0 fw-bold">Comentario</h5>
				    	<p>{formData.comentario === "" ? "Ninguno" : formData.comentario}</p>
				    </div>
			    </div>;

	const editar = <div className="row appear">
					    <div className="col-6 pb-2">
					    	<h5 className="mb-0 fw-bold">Contratación</h5>
					    	<DatePicker className="form-control text-center new-box" selected={formData.fecha_contratacion} 
					    		onChange={(date:Date) => handleChange(date, "fecha_contratacion")} locale="es"  dateFormat="P"/>
					    </div>
					    <div className="col-6 pb-2">
					    	<h5 className="mb-0 fw-bold">Pago cliente</h5>
					    	<DatePicker className="form-control text-center new-box" selected={formData.fecha_pago_cliente} 
					    		onChange={(date:Date) => handleChange(date, "fecha_pago_cliente")} locale="es"  dateFormat="P"/>
					    </div>
					    <div className="col-6 pb-2">
					    	<h5 className="mb-0 fw-bold">Renovación (Reporte)</h5>
					    	<DatePicker className="form-control text-center new-box" selected={formData.fecha_renovacion} 
					    		onChange={(date:Date) => handleChange(date, "fecha_renovacion")} locale="es" dateFormat="P"/>
					    </div>
					    <div className="col-6 pb-2">
					    	<h5 className="mb-0 fw-bold">Renovación interno</h5>
					    	<DatePicker className="form-control text-center new-box" selected={formData.fecha_renovacion_interno} 
					    		onChange={(date:Date) => handleChange(date, "fecha_renovacion_interno")} locale="es" dateFormat="P"/>
					    </div>
					    <div className="col-6 pb-2">
					    	<h5 className="mb-0 fw-bold">Vencimiento interno</h5>
					    	<DatePicker className="form-control text-center new-box" selected={formData.fecha_vencimiento_interno} 
					    		onChange={(date:Date) => handleChange(date, "fecha_vencimiento_interno")} locale="es" dateFormat="P"/>
					    </div>
					    <div className="col-6 mb-3">
					    	<label className="form-label">Tipo Pago:</label>
					    	<Select 
			                  options={catalogo.pagos}
			                  getOptionValue={(option) => `${option['id']}`}
			                  getOptionLabel={(option) => `${option['desc_tipo_pago']}`}
			                  onChange={(selectedOption)=>handleChangeSelect(selectedOption, "tipo_pago")}
			                  value={formData.tipo_pago}
			                  placeholder="Seleccione..."
			                />
					    </div>
					    <div className="col-6 pb-2">
					    	<h5 className="mb-0 fw-bold">Costo Contrato</h5>
					    	<input className="form-control" value={formData.costo_contrato}
							  	name="costo_contrato" onChange={handleChangeText} />
					    </div>
					    <div className="col-6 pb-2">
					    	<h5 className="mb-0 fw-bold">Costo anual</h5>
					    	<input className="form-control" value={formData.costo_compra}
							  	name="costo_compra" onChange={handleChangeText} />
					    </div>
					    <div className="col-6 pb-2">
					    	<h5 className="mb-0 fw-bold">Precio mensual (Sin IVA)</h5>
					    	<input className="form-control" value={formData.costo_total_servicio}
							  	name="costo_total_servicio" onChange={handleChangeText} />
					    </div>
					    <div className="col-12 pb-4">
					    	<h5 className="mb-0 fw-bold">Comentario</h5>
					    	<textarea className="form-control new-box" id="exampleFormControlTextarea1" 
					    		name="comentario" rows="3" onChange={handleChangeText}  value={formData.comentario}></textarea>
					    </div>
					    <div className="col-6 pb-2">
				    		<button className="btn btn-danger w-100" onClick={()=>setTipoTramite(0)}>Cancelar</button>
				    	</div>
					    <div className="col-6 pb-2">
				    		<button className="btn btn-success w-100" onClick={actionEditar}>Editar</button>
				    	</div>
				    </div>;

	const renovar = <div className="row new-box">
				    	<div className="col-12 pb-2">
				    		<h5>La fecha anterior de renovacion es:</h5><h5><span className="fw-bold">{formData.fecha_renovacion ? 
				    			((formData.fecha_renovacion.getDate()+1) + "/" + (formData.fecha_renovacion.getMonth()+1) + "/" + formData.fecha_renovacion.getFullYear()) : ""}</span></h5>
				    		<h5>La nueva fecha de renovacion será:</h5><h5><span className="fw-bold">{formData.fecha_renovacion ? 
				    			((formData.fecha_renovacion.getDate()+1) + "/" + (formData.fecha_renovacion.getMonth()+1) + "/" + (formData.fecha_renovacion.getFullYear()+1)) : ""}</span></h5>
				    		<h5 className="pt-4">¿Desea renovar el servicio?</h5>
				    	</div>
				    	<div className="col-6 pb-2">
				    		<button className="btn btn-danger w-100" onClick={()=>setTipoTramite(0)}>Cancelar</button>
				    	</div>
				    	<div className="col-6 pb-2">
				    		<button className="btn btn-success w-100" onClick={actionRenovar}>Si</button>
				    	</div>
				    </div>;

	const botonesMenu = <div className="row w-100">
					  		<div className="col-4">
					  			<Button variant="success" 
					  				className="w-100" onClick={()=>setTipoTramite(2)}>
						            <FontAwesomeIcon icon="fa-solid fa-calendar-plus" className="pe-2" />Renovar
						        </Button>
					  		</div>
					  		<div className="col-4 text-end">
						  		<Button variant="warning" 
						  			className="w-100" onClick={()=>setTipoTramite(1)}>
						            <FontAwesomeIcon icon="fa-solid fa-pen-to-square" className="pe-2" />Editar
						        </Button>
					  		</div>
					  		<div className="col-4 text-end">
						  		<Button variant="danger" 
						  			className="w-100" onClick={()=>setTipoTramite(3)}>
						            <FontAwesomeIcon icon="fa-solid fa-xmark" className="pe-2" />Suspender
						        </Button>
					  		</div>
					  	</div>;

	const suspender = <div className="row new-box">
				    	<div className="col-12 pb-2">
				    		<h5>El servicio quedara suspendido. No se tomara en cuenta la fecha de renovacion para las alertas o estadisticas del dashboard.</h5>
				    		<h5 className="pt-4">¿Desea suspender el servicio?</h5>
				    	</div>
				    	<div className="col-6 pb-2">
				    		<button className="btn btn-danger w-100" onClick={()=>setTipoTramite(0)}>Cancelar</button>
				    	</div>
				    	<div className="col-6 pb-2">
				    		<button className="btn btn-success w-100" onClick={actionExpirar}>Si</button>
				    	</div>
				    </div>;

	return(
		<div>
		{data.length !== 0 ? 
			<Modal show={show} onHide={reset} backdrop="static">
			  <Modal.Header closeButton>
			    <Modal.Title>Detalles del servicio</Modal.Title>
			  </Modal.Header>
	
			  <Modal.Body className="">
			  	<div className="row">
			  		<div className="col-auto">
			  			<img className="img-fluid" src={data.tipo_plan.tipo_servicio.imagen} />
			  		</div>
			  		<div className="col-6">
			  			<h4 className="mb-0 fw-bold">{data.tipo_plan.tipo_servicio.desc_servicio} {data.tipo_plan.desc_tipo_servicio ? ("- " + data.tipo_plan.desc_tipo_servicio) : ""}</h4>
				    <h4 className="pb-3">${data.tipo_plan.costo}</h4>
				    
			  		</div>
			  		<div className="col-12">
			  			<h4 className="pb-3">{data.dominio}</h4>
				  	</div>
				    
			    </div>
			    <StatusBar status={status} />

			    {!loading ? tipoTramite === 0 ? info 
			    	: tipoTramite === 1 ? editar 
			    	: tipoTramite === 2 ? renovar 
			    	: tipoTramite === 3 ? suspender 
			    	: "" 
			    	: <div className="spinner-border mx-auto my-auto" role="status">
				  		<span className="visually-hidden">Loading...</span>
					</div>}

			  </Modal.Body>
	
			  {(tipoTramite === 0 || tipoTramite === 4) ? <Modal.Footer>
			  			  	{tipoTramite === 0 ? botonesMenu : tipoTramite === 4 ? 
			  			  		<Button variant="primary" 
			  			  			className="w-100" onClick={reset}>
			  			            <FontAwesomeIcon icon="fa-solid fa-xmark" className="pe-2" />Cerrar
			  			        </Button> : ""}
			  			  </Modal.Footer> : ""}
			</Modal> : ""}
		</div>
	);
}

export default ServicioModal;